import React, { useState } from "react";
import AddForm from "./content/normal-courses/AddForm";
import { useParams, useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import { addCourse } from "../../../services/course";

const AddCourse = () => {
  const history = useHistory();
  let { type } = useParams();

  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    courseTitle: "",
    duration: 0,
    courseDescription: "",
    about: "",
    what_you_get: "",
    is_locked: "false",
    is_hidden: "false",
    gift: "false",
    img: null,
    price: null,
    outside_price: null,
    courseInformation: "",
    courseVideo: "",
  });

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <p className="md:text-base 2xl:text-2xl">دورات عادية</p>
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">اضافة دورة</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row items-center justify-end x-sm:space-x-4 md:space-x-8 2xl:space-x-16 border-b border-gray-200 md:mb-2 md:pb-2 2xl:mb-4 2xl:pb-4 md:text-xl 2xl:text-4xl">
            <div>محتوى</div>
            <div className="text-reghda-blue">معلومات عامة</div>
          </div>
          <div>
            <div className="x-sm:p-4 md:p-8 2xl:p-16">
              <AddForm
                formState={formState}
                setFormState={setFormState}
                paid={type === "paid"}
              ></AddForm>

              <div>
                <button
                  onClick={async (e) => {
                    if (
                      type === "free" &&
                      (formState.courseTitle.length > 0 ||
                        formState.courseDescription.length > 0 ||
                        formState.img)
                    ) {
                      setLoading(true);
                      const formData = new FormData();
                      formData.append(`title`, formState.courseTitle);

                      formData.append(
                        `description`,
                        formState.courseDescription
                      );

                      formData.append(`duration`, formState.duration);

                      if (formState.img) {
                        formData.append(`picture`, formState.img);
                      }

                      formData.append(`is_hidden`, formState.is_hidden);

                      let res = await addCourse(formData, type);

                      setLoading(false);
                      res &&
                        res.data &&
                        history.push(
                          `/admin/edit-course/${type}/${res.data.id}`
                        );
                    }
                    if (type === "paid") {
                      setLoading(true);
                      const formData = new FormData();
                      formData.append(`title`, formState.courseTitle);
                      formData.append(
                        `description`,
                        formState.courseDescription
                      );
                      formData.append(`duration`, formState.duration);
                      formData.append(`about_course`, formState.about);

                      formData.append(`is_locked`, formState.is_locked);
                      formData.append(`is_hidden`, formState.is_hidden);

                      formData.append(`price`, formState.price);
                      formData.append(`outside_price`, formState.outside_price);

                      if (formState.img) {
                        formData.append(`picture`, formState.img);
                      }

                      if (formState.what_you_get) {
                        formData.append(`what_you_get`, formState.what_you_get);
                      }
                      if (formState.courseInformation) {
                        formData.append(
                          `course_information`,
                          formState.courseInformation
                        );
                      }

                      if (formState.courseVideo) {
                        formData.append(`course_video`, formState.courseVideo);
                      }

                      if (formState.gift) {
                        formData.append(`gift`, formState.gift);
                      }

                      let res = await addCourse(formData, type);

                      setLoading(false);
                      res &&
                        res.data &&
                        history.push(
                          `/admin/edit-course/${type}/${res.data.id}`
                        );
                    }
                  }}
                  className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl"
                >
                  التالي
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddCourse;
