import React from "react";

import { InputBase } from "@material-ui/core";

const CustomTextField = ({
  error,
  helperText,
  className,
  label,
  labelColor,
  labelSize,
  labelClassname,
  ...rest
}) => {
  className =
    className + (error || helperText ? " border-2 border-red-500" : "");
  return (
    <>
      <div className="flex flex-col w-full">
        {label ? (
          <label
            className={labelClassname}
            style={{ color: labelColor, fontSize: labelSize }}
            htmlFor={label}>
            {label}
          </label>
        ) : null}
        <InputBase {...rest} className={className} />
        {error ? (
          <div className="">
            <h1 className="px-4 py-2 font-sans text-base text-red-500 ">
              {helperText}
            </h1>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CustomTextField;
