import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";

import ShipmentModal from "./content/courses-orders/ShipmentModal";
import PictureModal from "./content/courses-orders/PictureModal";
import { changeOrderStatus } from "../../../services/courseOrders";
import { enroll } from "../../../services/enrollment";

const CourseOrderDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [pictureIsOpen, setPictureIsOpen] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/orders/my-orders/${id}/`,
    fetcher
  );

  if (!data) {
    return <LoaderModal modalIsOpen={true}></LoaderModal>;
  }

  return (
    <div className="min-h-screen h-full flex flex-col space-y-10 overflow-y-auto x-sm:px-4 md:px-0 pb-8">
      <div className="px-8 pt-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          تفاصيل الطلب
        </h3>
      </div>
      <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
        <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-6 2xl:p-10">
          <div className="flex flex-row items-end justify-end x-sm:pb-2 md:pb-4 2xl:pb-8">
            <div className="flex flex-row space-x-1 items-center self-end font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
              <h3>المشتريات</h3>
            </div>
          </div>
          <div className="flex flex-col x-sm:space-y-4 md:space-y-8 2xl:space-y-12">
            <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-blue x-sm:text-xs md:text-base 2xl:text-4xl border-b-2 md:pb-4 2xl:pb-8 border-reghda-blue">
              <i className="w-1/3">السعر</i>
              <i className="w-1/3">المنتج</i>
              <i className="w-1/3">صنف</i>
            </div>
            {data &&
              data.items.map((item) => (
                <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
                  <i className="w-1/3">
                    {item.price} {data.payment_method === "تحويل بنكي" && "دج"}
                    {data.payment_method === "Card" && "دولار"}
                    {data.payment_method === "Paypal" && "دولار"}
                  </i>
                  <i className="w-1/3">{item.product_title}</i>
                  <i className="w-1/3">{item.type}</i>
                </div>
              ))}
            {/* <div className="flex flex-row space-x-4 justify-between text-lg font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
              <i>2</i>
              <i>1 ريال</i>
              <i>العطر 1</i>
            </div>
            <div className="flex flex-row space-x-4 justify-between text-lg font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
              <i>1</i>
              <i>1 ريال</i>
              <i>العطر 2</i>
            </div> */}
            <div className="flex flex-row space-x-4 justify-between font-extrabold text-reghda-blue x-sm:text-base md:text-lg 2xl:text-5xl">
              <i></i>
              <i>
                المجموع: {data.total_cost}{" "}
                {data.payment_method === "تحويل بنكي" && "دج"}
                {data.payment_method === "Card" && "دولار"}
                {data.payment_method === "Paypal" && "دولار"}
              </i>
              <i></i>
            </div>
          </div>
        </div>
        <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
          <div className="flex flex-col items-center justify-around space-y-4 x-sm:pb-2 md:pb-4 2xl:pb-8">
            <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>معلومات المشتري</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3> {data.given_name} </h3>
              <h3 className="text-reghda-blue">:الاسم</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3> {data.family_name} </h3>
              <h3 className="text-reghda-blue">:اللقب</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3> {data.phone_number} </h3>
              <h3 className="text-reghda-blue">:هاتف</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.user.email}</h3>
              <h3 className="text-reghda-blue">:البريد الالكتروني</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.country}</h3>
              <h3 className="text-reghda-blue">:البلد</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.address}</h3>
              <h3 className="text-reghda-blue">:العنوان</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.second_address}</h3>
              <h3 className="text-reghda-blue">:العنوان الثاني</h3>
            </div>
            <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
              <h3>{data.payment_method}</h3>
              <h3 className="text-reghda-blue">:طريقة الدفع</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
        <div className="x-sm:w-full md:w-1/3"></div>
        {data.payment_method === "تحويل بنكي" ? (
          <div className="x-sm:w-full md:w-1/3 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
            <div className="flex flex-col items-center justify-around space-y-8 x-sm:pb-2 md:pb-4 2xl:pb-8">
              <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs md:text-base 2xl:text-4xl">
                <h3>معلومات التحويل البنكي</h3>
              </div>

              <div className="flex flex-row space-x-6 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                <>
                  <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                    <h3
                      onClick={() => setPictureIsOpen(true)}
                      className="text-reghda-black underline cursor-pointer">
                      إضغط هنا لترى
                    </h3>
                  </div>
                </>
                <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs md:text-base 2xl:text-4xl">
                  <h3>دليل إثبات الدفع</h3>
                </div>
              </div>

              {data.status === "مقبول" && (
                <>
                  <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                    <h3 className="text-green-400">مقبول</h3>
                  </div>
                </>
              )}

              {data.status === "في الإنتظار" && (
                <div className="flex flex-row space-x-6 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                  <button
                    onClick={async () => {
                      setLoading(true);
                      let res = await changeOrderStatus({
                        order_id: id,
                        status: "مرفوض",
                      });
                      if (res && res.status === 200) {
                        mutate(`https://${domain}/orders/my-orders/${id}/`);
                      } else {
                        alert("retry! something wrong");
                      }
                      setLoading(false);
                    }}
                    className="bg-red-500 text-white p-2 rounded-xl md:text-base 2xl:text-xl outline-none focus:outline-none">
                    إرفض
                  </button>
                  <button
                    onClick={async () => {
                      setLoading(true);
                      let courseIds = data.items.filter(
                        (item) => item.type === "دورة"
                      );
                      courseIds = courseIds.map((item) => item.product);
                      // enroll
                      let result = await enroll({
                        course: courseIds,
                        user: data.user.id,
                      });

                      let res = await changeOrderStatus({
                        order_id: id,
                        status: "مقبول",
                      });

                      if (res && res.status === 200) {
                        mutate(`https://${domain}/orders/my-orders/${id}/`);
                      } else {
                        alert("retry! something wrong");
                      }
                      setLoading(false);
                    }}
                    className="bg-green-300 text-white p-2 rounded-xl md:text-base 2xl:text-xl outline-none focus:outline-none">
                    إقبل
                  </button>
                  <h3 className="text-reghda-black">في الإنتظار</h3>
                </div>
              )}
              {data.status === "مرفوض" && (
                <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-sm md:text-base 2xl:text-4xl">
                  <h3 className="text-red-500">مرفوض</h3>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="x-sm:w-full md:w-1/3"></div>
        )}

        {data.need_shipping &&
        (data.payment_method !== "تحويل بنكي" ||
          (data.payment_method === "تحويل بنكي" && data.status === "مقبول")) ? (
          <div className="x-sm:w-full md:w-1/3 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
            <div className="flex flex-col items-center justify-around space-y-4 x-sm:pb-2 md:pb-4 2xl:pb-8">
              <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs md:text-base 2xl:text-4xl">
                <h3>معلومات الشحن</h3>
              </div>

              {data.shipping_status === "Shipped" && (
                <>
                  <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                    <h3 className="text-green-400">تم الشحن</h3>
                  </div>
                  <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                    <h3> {data.tracking} </h3>
                    <h3 className="text-reghda-blue">:رقم التتبع</h3>
                  </div>
                  <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                    <h3> {data.company} </h3>
                    <h3 className="text-reghda-blue">:شركة الشحن</h3>
                  </div>
                  <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                    <h3> {data.notice} </h3>
                    <h3 className="text-reghda-blue">:ملاحظة</h3>
                  </div>
                </>
              )}
              {data.shipping_status === "Not shipped" && (
                <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
                  <button
                    onClick={() => setIsOpen(true)}
                    className="bg-reghda-black text-white p-2 rounded-xl md:text-base 2xl:text-xl">
                    اشحن
                  </button>
                  <h3 className="text-red-500">لم يتم الشحن بعد</h3>
                </div>
              )}
              {/* {data.shipping_status === "Cancelled" && (
              <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-sm md:text-base 2xl:text-4xl">
                <button className="bg-reghda-black text-white p-2 rounded-xl md:text-base 2xl:text-xl">
                  اشحن
                </button>
                <h3 className="text-yellow-300">الشحن ملغى</h3>
              </div>
            )} */}
            </div>
          </div>
        ) : (
          <div className="x-sm:w-full md:w-1/3"></div>
        )}
      </div>
      {data && data.payment_receipt && (
        <PictureModal
          closing={() => setPictureIsOpen(false)}
          url={data.payment_receipt}
          isOpen={pictureIsOpen}></PictureModal>
      )}
      <ShipmentModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}
        mutate={() => mutate(`https://${domain}/orders/my-orders/${id}/`)}
        id={id}></ShipmentModal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CourseOrderDetail;

// import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import LoaderModal from "./content/LoaderModal";

// import useSWR, { mutate } from "swr";
// import { fetcher } from "../../../services/fetcher";
// import { domain } from "../../../global/domain";
// import PictureModal from "./content/courses-orders/PictureModal";
// import { changeOrderStatus } from "../../../services/courseOrders";
// import { enroll } from "../../../services/enrollment";

// const CourseOrderDetail = () => {
//   const { id } = useParams();
//   const [loading, setLoading] = useState(false);
//   const [modalIsOpen, setIsOpen] = useState(false);

//   const { data, error } = useSWR(
//     `https://${domain}/orders/my-orders/${id}/`,
//     fetcher
//   );

//   if (!data) {
//     return <LoaderModal modalIsOpen={true}></LoaderModal>;
//   }

//   return (
//     <div className="min-h-screen h-full flex flex-col space-y-10 overflow-y-auto x-sm:px-4 md:px-0 pb-8">
//       <div className="px-8 pt-8 text-right">
//         <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
//           تفاصيل الطلب
//         </h3>
//       </div>
//       <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
//         <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-6 2xl:p-10">
//           <div className="flex flex-row items-end justify-end x-sm:pb-2 md:pb-4 2xl:pb-8">
//             <div className="flex flex-row space-x-1 items-center self-end font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
//               <h3>المشتريات</h3>
//             </div>
//           </div>
//           <div className="flex flex-col x-sm:space-y-4 md:space-y-8 2xl:space-y-12">
//             <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-blue x-sm:text-xs md:text-base 2xl:text-4xl border-b-2 md:pb-4 2xl:pb-8 border-reghda-blue">
//               <i className="w-1/3">السعر</i>
//               <i className="w-1/3">المنتج</i>
//               <i className="w-1/3">صنف</i>
//             </div>
//             {data &&
//               data.items.map((item) => (
//                 <div className="flex flex-row items-center justify-center text-center font-bold text-reghda-black x-sm:text-xs md:text-base 2xl:text-4xl">
//                   <i className="w-1/3">
//                     {item.price}{" "}
//                     {data.payment_method === "تحويل بنكي" ? "دج" : "دولار"}
//                   </i>
//                   <i className="w-1/3">{item.product_title}</i>
//                   <i className="w-1/3">{item.type}</i>
//                 </div>
//               ))}
//             <div className="flex flex-row space-x-4 justify-between font-extrabold text-reghda-blue x-sm:text-base md:text-lg 2xl:text-5xl">
//               <i></i>
//               <i>
//                 المجموع: {data.total_cost}{" "}
//                 {data.payment_method === "تحويل بنكي" ? "دج" : "دولار"}
//               </i>
//               <i></i>
//             </div>
//           </div>
//         </div>
//         <div className="x-sm:w-full md:w-1/2 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
//           <div className="flex flex-col items-center justify-around space-y-4 x-sm:pb-2 md:pb-4 2xl:pb-8">
//             <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//               <h3>معلومات المشتري</h3>
//             </div>
//             <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//               <h3> {data.user.given_name} </h3>
//               <h3 className="text-reghda-blue">:الاسم</h3>
//             </div>
//             <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//               <h3> {data.user.family_name} </h3>
//               <h3 className="text-reghda-blue">:اللقب</h3>
//             </div>
//             <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//               <h3>{data.user.email}</h3>
//               <h3 className="text-reghda-blue">:البريد الالكتروني</h3>
//             </div>
//             <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//               <h3>{data.phone_number}</h3>
//               <h3 className="text-reghda-blue">:رقم الهاتف</h3>
//             </div>
//             <div className="flex flex-row space-x-3 items-center self-end font-semibold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//               <h3>{data.payment_method}</h3>
//               <h3 className="text-reghda-blue">:طريقة الدفع</h3>
//             </div>
//           </div>
//         </div>
//       </div>
//       {data && data.payment_method === "تحويل بنكي" && (
//         <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col-reverse x-sm:gap-y-6 md:gap-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
//           <div className="x-sm:w-full md:w-1/3"></div>
//           <div className="x-sm:w-full md:w-1/3 bg-white rounded-lg shadow-2xl x-sm:p-2 md:p-4 2xl:p-8">
//             <div className="flex flex-col items-center justify-around space-y-8 x-sm:pb-2 md:pb-4 2xl:pb-8">
//               <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs md:text-base 2xl:text-4xl">
//                 <h3>معلومات التحويل البنكي</h3>
//               </div>

//               <div className="flex flex-row space-x-6 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//                 <>
//                   <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//                     <h3
//                       onClick={() => setIsOpen(true)}
//                       className="text-reghda-black underline cursor-pointer"
//                     >
//                       إضغط هنا لترى
//                     </h3>
//                   </div>
//                 </>
//                 <div className="flex flex-row space-x-1 items-center self-end font-extrabold x-sm:text-xs md:text-base 2xl:text-4xl">
//                   <h3>دليل إثبات الدفع</h3>
//                 </div>
//               </div>

//               {data.status === "مقبول" && (
//                 <>
//                   <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//                     <h3 className="text-green-400">مقبول</h3>
//                   </div>
//                 </>
//               )}

//               {data.status === "في الإنتظار" && (
//                 <div className="flex flex-row space-x-6 items-center self-end font-extrabold x-sm:text-xs sm:text-sm md:text-base 2xl:text-4xl">
//                   <button
//                     onClick={async () => {
//                       setLoading(true);
//                       let res = await changeOrderStatus({
//                         order_id: id,
//                         status: "مرفوض",
//                       });
//                       if (res && res.status === 200) {
//                         mutate(
//                           `https://${domain}/orders/my-orders/${id}/`
//                         );
//                       } else {
//                         alert("retry! something wrong");
//                       }
//                       setLoading(false);
//                     }}
//                     className="bg-red-500 text-white p-2 rounded-xl md:text-base 2xl:text-xl outline-none focus:outline-none"
//                   >
//                     إرفض
//                   </button>
//                   <button
//                     onClick={async () => {
//                       setLoading(true);
//                       let courseIds = data.items.filter(
//                         (item) => item.type === "دورة"
//                       );
//                       let bookIds = data.items.filter(
//                         (item) => item.type === "كتاب"
//                       );

//                       courseIds = courseIds.map((item) => item.product);
//                       bookIds = bookIds.map((item) => item.product);

//                       // enroll
//                       let result = await enroll({
//                         course: courseIds,
//                         book: bookIds,
//                         user: data.user.id,
//                       });

//                       let res = await changeOrderStatus({
//                         order_id: id,
//                         status: "مقبول",
//                       });
//                       if (res && res.status === 200) {
//                         mutate(
//                           `https://${domain}/orders/my-orders/${id}/`
//                         );
//                       } else {
//                         alert("retry! something wrong");
//                       }
//                       setLoading(false);
//                     }}
//                     className="bg-green-300 text-white p-2 rounded-xl md:text-base 2xl:text-xl outline-none focus:outline-none"
//                   >
//                     إقبل
//                   </button>
//                   <h3 className="text-reghda-black">في الإنتظار</h3>
//                 </div>
//               )}

//               {data.status === "مرفوض" && (
//                 <div className="flex flex-row space-x-12 items-center self-end font-extrabold x-sm:text-sm md:text-base 2xl:text-4xl">
//                   <h3 className="text-red-500">مرفوض</h3>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//       {data && data.payment_receipt && (
//         <PictureModal
//           closing={() => setIsOpen(false)}
//           url={data.payment_receipt}
//           isOpen={modalIsOpen}
//         ></PictureModal>
//       )}
//       <LoaderModal modalIsOpen={loading}></LoaderModal>
//     </div>
//   );
// };

// export default CourseOrderDetail;
