import React, { useState, useRef } from "react";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../../services/fetcher";
import { domain } from "../../../global/domain";

import { Collapse, Modal } from "@material-ui/core";

import TopSentInbox from "./content/mails/TopSentInbox";
import SendMail from "./content/mails/SentMail";
import Sent from "./content/mails/Sent";
import LoaderModal from "./content/LoaderModal";
import Pagination from "../Pagination";

import SendIcon from "@material-ui/icons/Send";

const Mails = () => {
  const [send, setSend] = useState(false);
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pagesize, setPagesize] = useState(10);
  const myRef = useRef(null);
  const { data, mutate, error } = useSWR(
    `https://${domain}/mails/all?page=${page + 1}&page_size=${pagesize}`,
    fetcher
  );
  return (
    <>
      <div
        ref={myRef}
        className="min-h-screen h-full flex flex-col overflow-auto md:pb-12">
        <div className="p-8 text-right">
          <p className="md:text-base 2xl:text-2xl">مرحبا بك</p>
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            رسائل البريد
          </h3>
        </div>
        <div className="h-full x-sm:px-4 md:px-6 2xl:p-12 space-y-16">
          <TopSentInbox />
          <SendMail
            send={send}
            mutate={mutate}
            setLoading={setLoading}
            reply={reply}
            setSend={setSend}
          />

          <div
            style={{ direction: "rtl" }}
            className=" bg-white flex flex-col items-center x-sm:w-full md:w-11/12 x-sm:p-4 md:p-6 2xl:p-12  rounded-lg shadow-2xl gap-y-4">
            <Pagination
              data={data}
              pagesize={pagesize}
              setPage={setPage}
              page={page}
            />
            <div className="w-full">
              <div className="flex flex-row items-center justify-center rounded-md capitalize font-bold w-full">
                <h1 className="w-1/3 flex flex-row items-center justify-center text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl py-4">
                  المتلقي
                </h1>
                <h6 className="w-1/3 flex flex-row items-center justify-center text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl py-4">
                  موضوع
                </h6>
                <h6 className="w-1/3 flex flex-row items-center justify-center text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl py-4">
                  إرسال
                </h6>
                <div className="flex items-center justify-center p-2"></div>
              </div>

              <div className="flex flex-col w-full">
                {data &&
                  Array.isArray(data.results) &&
                  data?.results?.map((email) => (
                    <Sent key={email.id} detail={email} mutate={mutate} />
                  ))}
              </div>
            </div>
            <Pagination
              data={data}
              pagesize={pagesize}
              setPage={setPage}
              page={page}
            />
          </div>
        </div>
        <LoaderModal modalIsOpen={loading}></LoaderModal>
        <div className="h-20"></div>
      </div>
      <button
        title="إرسال"
        onClick={() => {
          if (send) {
            myRef.current.scrollTo(0, 0);
          } else {
            setSend(true);
            myRef.current.scrollTo(0, 0);
          }
        }}
        className="fixed bottom-12 flex items-center justify-center left-12 bg-reghda-blue text-white py-4 px-4 rounded-full shadow-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl outline-none focus:outline-none capitalize">
        <SendIcon fontSize="small" style={{ color: "white" }} />
      </button>
    </>
  );
};

export default Mails;
