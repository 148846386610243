import React from "react";
import { Link } from "react-router-dom";
import { deleteProduct } from "../../../../../services/products";
import { IconContext } from "react-icons";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Fill } from "react-icons/ri";

const ProductCard = ({ detail, deleteOne }) => {
  return (
    <div className="rounded-2xl shadow-2xl bg-white x-sm:p-2 md:w-56 md:p-4 2xl:w-80 2xl:p-8 flex flex-col items-center justify-around">
      <img
        className="flex-1"
        alt="logo"
        className="max-h-72"
        src={
          detail.images.length && detail.images[0]
            ? detail.images[0]
            : "/images/product.png"
        }
      />
      <h1 className="text-reghda-black font-bold md:text-lg 2xl:text-4xl">
        {detail.name}
      </h1>
      <div className="w-full flex flex-row items-center justify-between text-center">
        <h6 className="w-1/2 text-reghda-blue md:text-base 2xl:text-xl">
          {detail.price} دج
        </h6>
        <h6 className="w-1/2 text-gray-400 md:text-base 2xl:text-xl">
          {detail.category.length ? detail.category[0].name : "بدون صنف"}
        </h6>
      </div>
      <div className="w-full flex flex-row items-center justify-between">
        <button onClick={deleteOne} className="">
          <IconContext.Provider
            value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
          >
            <RiDeleteBin5Fill></RiDeleteBin5Fill>
          </IconContext.Provider>
        </button>
        <h6 className=" md:text-base 2xl:text-xl font-extrabold">
          {detail.is_available ? (
            <span className="text-green-300">متوفر</span>
          ) : (
            <span className="text-red-500">غير متوفر</span>
          )}
        </h6>
        <Link className="" to={`/admin/store/edit-product/${detail.id}`}>
          <IconContext.Provider
            value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
          >
            <FiEdit></FiEdit>
          </IconContext.Provider>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
