import React, { useState, useRef, useEffect } from "react";

import useSWR, { mutate } from "swr";
import { domain } from "../../../../../global/domain";
import { fetcher, fetcherAuth } from "../../../../../services/fetcher";
import { sendMails } from "../../../../../services/mail";

import {
  Modal,
  Collapse,
  TextField,
  Radio,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SortIcon from "@material-ui/icons/Sort";
import CancelIcon from "@material-ui/icons/Cancel";

import * as Yup from "yup";
import { useFormik } from "formik";
// import { toast } from "react-toastify";

import CustomTextField from "../../../textField";
import RtlConversion from "../../../RtlConversion";
import TextEditorContent from "./TextEditorContent";

const SendMail = ({ mutate, setLoading, reply, setSend, send }) => {
  const [receiver, setReceiver] = useState([]);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [addFilter, setAddFilter] = useState(false);
  const [checked, setChecked] = useState("owner");
  const [selectAll, setSelectAll] = useState(false);
  const [courses, setCourses] = useState([]);
  const { data, error } = useSWR(
    `https://${domain}/mails/contacts?in_course=${
      checked === "owner" ? courses.map((item) => item.id).toString() : ""
    }&not_in=${
      checked === "non-owners" ? courses.map((item) => item.id).toString() : ""
    }`,
    fetcher
  );
  const { data: coursesList, error: coursesListError } = useSWR(
    `https://${domain}/courses/all/`,
    fetcher
  );
  // console.log(
  //   `https://${domain}/mails/contacts?in_course=${
  //     checked === "owner" ? courses.map((item) => item.id).toString() : ""
  //   }&not_in=${
  //     checked === "non-owners" ? courses.map((item) => item.id).toString() : ""
  //   }`,
  //   `https://${domain}/courses/all/`
  // );
  useEffect(() => {
    if (receiver === [] || !receiver.includes(reply)) {
      // setChecked("one");
      setReceiver(reply ? [reply] : []);
    }
  }, [reply]);
  const SubSchema = Yup.object().shape({
    subject: Yup.string().required("هذه الخانة مطلوبه"),
    receiver: Yup.array()
      .min(1, "يجب أن يحتوي على بريد إلكتروني واحد على الأقل")
      .of(Yup.string().email("بريد إلكتروني خاطئ"))
      .required("هذه الخانة مطلوبه"),
    content: Yup.string().required("هذه الخانة مطلوبه"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      receiver: receiver,
      subject: subject,
      content: content,
    },
    validationSchema: SubSchema,
    onSubmit: async (values) => {
      setLoading(true);
      //console.log(values);
      let res = await sendMails({
        receivers: values.receiver, // receiver email, type: email
        subject: values.subject, // email subject, type: string
        text_body: values.content,
      });
      //console.log("rs", res);
      if (res && res.status === 200) {
        mutate();
        alert("تم إرسال بريدك بنجاح");
        setReceiver([]);
        setSubject("");
        setContent("");
      } else {
        alert("لم يتم إرسال رسالتك");
      }
      setLoading(false);
      setSend(false);
    },
  });

  // console.log(
  //   "test",
  //   data?.every((r) => receiver?.includes(r))
  // );
  return (
    <Modal
      open={send}
      onClose={() => setSend(false)}
      style={{
        direction: "rtl",
      }}
      className=" overflow-auto p-2 md:p-12 ">
      <form
        onSubmit={formik.handleSubmit}
        style={{ direction: "rtl" }}
        className="flex flex-col bg-white w-11/12 md:w-2/3 mx-auto shadow mt-10 gap-y-3 p-4 sm:p-8 rounded-md ">
        <div className="w-full flex flex-col mx-auto gap-y-3">
          <h1 className="md:text-lg 2xl:text-2xl font-extrabold capitalize px-3">
            المتلقي
          </h1>
          <div className="flex flex-row gap-x-3 items-center justify-center">
            <RtlConversion rtl={true}>
              <Autocomplete
                fullWidth
                id="tags-id"
                value={receiver}
                options={data ? data : []}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                onChange={(e, value, reason) => {
                  setReceiver(value);
                }}
                disableCloseOnSelect
                limitTags={3}
                freeSolo
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </React.Fragment>
                )}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder={"عناوين البريد الإلكتروني"}
                    size="small"
                    variant="outlined"
                    error={
                      formik.touched.receiver && Boolean(formik.errors.receiver)
                    }
                    helperText={
                      formik.touched.receiver && formik.errors.receiver
                    }
                  />
                )}
              />
            </RtlConversion>

            <button
              title={"email filtering"}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setAddFilter(!addFilter);
              }}>
              {addFilter ? (
                <CancelIcon fontSize="small" style={{ color: "#4c868e" }} />
              ) : (
                <SortIcon fontSize="small" style={{ color: "#4c868e" }} />
              )}
            </button>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    data?.every((r) => receiver?.includes(r)) &&
                    data?.length > 0
                      ? true
                      : false
                  }
                  disabled={!data || data?.length === 0}
                  onChange={() => {
                    if (!data?.every((r) => receiver?.includes(r))) {
                      data ? setReceiver(data) : setReceiver([]);
                    } else {
                      setReceiver([]);
                    }
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={"اختر الكل"}
            />
          </div>

          <Collapse
            in={addFilter}
            className="w-full flex flex-col mx-auto gap-y-3">
            <RtlConversion rtl={true}>
              <Autocomplete
                fullWidth
                id="tags-id"
                value={courses}
                options={coursesList ? coursesList : []}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, value, reason) => {
                  setCourses(value);
                }}
                disableCloseOnSelect
                limitTags={3}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </React.Fragment>
                )}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder={"الدورات"}
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </RtlConversion>
            <div className="flex flex-row gap-x-3 items-center justify-center">
              <FormControlLabel
                checked={checked === "owner"}
                value="owner"
                control={<Radio color="primary" />}
                label={"فقط عناوين البريد الإلكتروني للمالكين"}
                onClick={() => {
                  setChecked("owner");
                }}
              />
              <FormControlLabel
                checked={checked === "non-owners"}
                value="non-owners"
                control={<Radio color="primary" />}
                label={"فقط عناوين البريد الإلكتروني لغير المالكين"}
                onClick={() => {
                  setChecked("non-owners");
                }}
              />
            </div>
          </Collapse>
        </div>
        <div className="w-full flex flex-col mx-auto gap-y-3">
          <h1 className="md:text-lg 2xl:text-2xl font-extrabold capitalize px-3">
            موضوع
          </h1>
          <CustomTextField
            name="subject"
            placeholder="موضوع"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={{
              background: "#FFFFFF",
              border: "1px solid #D2D6DA",
              boxSizing: "border-box",
              borderRadius: "8px",
            }}
            className="px-3 py-1 text-base"
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={formik.touched.subject && formik.errors.subject}
          />
        </div>
        <fieldset className="w-full flex flex-col mx-auto gap-y-3">
          <label
            className="md:text-lg 2xl:text-2xl font-extrabold capitalize px-3"
            htmlFor="title">
            المحتوى
          </label>
          <TextEditorContent
            initialContentState={content}
            formState={content}
            setFormState={setContent}></TextEditorContent>
          {formik.touched.content && Boolean(formik.errors.content) ? (
            <span className="text-red-600 ">
              {formik.touched.content && formik.errors.content}
            </span>
          ) : null}
        </fieldset>
        <div className="w-full flex items-center justify-end mx-auto gap-x-3">
          <button
            onClick={() => setSend(false)}
            type="button"
            className="bg-gray-400 text-white p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-2xl outline-none focus:outline-none">
            إلغاء
          </button>
          <button
            type="submit"
            className="bg-reghda-blue text-white p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-2xl outline-none focus:outline-none">
            إرسال
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default SendMail;
