import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });

export default function RtlConversion({ children, rtl = false }) {
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={rtl ? rtlTheme : ltrTheme}>
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
}
