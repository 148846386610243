import Axios from "axios";
import { domain } from "../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function sendMail(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/mails/text/send`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function sendMails(data) {
  try {
    const response = await Axios.post(
      `https://${domain}/mails/text/send/multiple`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function deleteMail(id) {
  try {
    const response = await Axios.delete(`https://${domain}/mails/delete/${id}`);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
