import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher, fetcherAuth } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import {
  getTheTokenFromStorage,
  getTheUserFromStorage,
} from "../../../services/auth";
import { updateRolesUser, updateUserPassword } from "../../../services/users";
import { CircularProgress } from "@material-ui/core";

const CoursesOrders = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [roles, setRoles] = useState({
    is_editor: false,
    is_staff: false,
    is_superuser: false,
  });

  const { id } = useParams();
  const token = getTheTokenFromStorage();

  const { data } = useSWR(
    [`https://${domain}/users/manage/all/${id}`, getTheTokenFromStorage()],
    fetcherAuth
  );

  //Initialize Values
  useEffect(() => {
    setRoles({
      is_editor: data && data.is_editor,
      is_staff: data && data.is_staff,
      is_superuser: data && data.is_superuser,
    });
  }, [data]);

  //If superuser check others
  useEffect(() => {
    if (roles.is_superuser) {
      setRoles({ ...roles, is_editor: true, is_staff: true });
    }
  }, [roles.is_superuser]);

  //If staff check editor
  useEffect(() => {
    if (roles.is_staff) {
      setRoles({ ...roles, is_editor: true });
    }
  }, [roles.is_staff]);

  //Admin password change
  const handleChangePassword = async () => {
    const response = await updateUserPassword(
      {
        user: id,
        new_password: newPassword,
      },
      token
    );
    setNewPassword("");
    setChangePassword(false);
    if (response.status === 200) {
      alert("تم تحديث كلمة المرور بنجاح");
    } else {
      alert("حدثت مشكلة يرجى المحاولة مرة أخرى");
    }
  };

  //Admin Attributes roles
  const handleChangeRoles = async () => {
    console.log({
      user: id,
      ...roles,
    });
    const response = await updateRolesUser(
      {
        user: id,
        ...roles,
      },
      token
    );

    if (response.status === 200) {
      alert("تم تحديث الأدوار بنجاح");
    } else {
      alert("حدثت مشكلة يرجى المحاولة مرة أخرى");
    }
  };
  if (!data)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            بيانات المستخدم
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
          <div className="h-48 flex justify-center items-center w-full bg-white rounded-lg">
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  if (data)
    return (
      <div className="min-h-screen h-full flex flex-col overflow-y-auto base-clamp">
        <div className="p-8 text-right">
          <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
            بيانات المستخدم
          </h3>
        </div>
        <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
          <div className="bg-white x-sm:w-full md:w-full rounded-lg p-4 md:p-8 2xl:p-16 flex flex-col space-y-2 md:space-y-8 2xl:space-y-16 rtl base-clamp">
            <div className="flex items-center gap-1 base-clamp">
              <span className="md:w-36 base-clamp">الاسم</span>
              <textarea
                readOnly
                value={data.username}
                rows="1"
                id="title"
                type="text"
                class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </div>
            <div className="flex items-center gap-1 ">
              <span className="md:w-36"> البريد الإلكتروني</span>
              <textarea
                readOnly
                value={data.email}
                rows="1"
                id="title"
                type="text"
                class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
              />
            </div>
            <div className="flex  gap-1 ">
              <span className="md:w-36">الأدوار و المسؤوليات</span>
              <div className="space-y-4">
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    value={roles.is_editor}
                    checked={roles.is_editor}
                    onChange={() =>
                      setRoles({ ...roles, is_editor: !roles.is_editor })
                    }
                  />
                  <label>محرر</label>
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    value={roles.is_staff}
                    checked={roles.is_staff}
                    onChange={() =>
                      setRoles({ ...roles, is_staff: !roles.is_staff })
                    }
                  />
                  <label> طاقم العمل</label>
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    value={roles.is_superuser}
                    checked={roles.is_superuser}
                    onChange={() => {
                      setRoles({ ...roles, is_superuser: !roles.is_superuser });
                    }}
                  />
                  <label>المشرف العام</label>
                </div>
                <div className="flex items-center gap-1">
                  <button
                    className="bg-reghda-blue rounded-md px-4 py-2 text-white"
                    onClick={handleChangeRoles}>
                    حفظ الأدوار
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center gap-1">
              <button
                className="bg-reghda-blue rounded-md px-4 py-2 text-white"
                onClick={() => setChangePassword(!changePassword)}>
                {changePassword ? "الغاء" : "تغيير كلمة السر"}
              </button>
            </div>
            {changePassword && (
              <div className="flex items-center gap-1 ">
                <span className="w-32"> تغيير كلمة السر</span>
                <textarea
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  rows="1"
                  id="title"
                  type="text"
                  class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                />
                <button
                  className="bg-reghda-blue rounded-md px-4 py-2 text-white"
                  onClick={handleChangePassword}>
                  التأكيد
                </button>
              </div>
            )}
          </div>
        </div>

        <LoaderModal modalIsOpen={loading}></LoaderModal>
      </div>
    );
};

export default CoursesOrders;
