import React, { useState } from "react";
import { IconContext } from "react-icons";
import {
  AiOutlinePlus,
  AiOutlineClose,
  AiFillEdit,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from "react-icons/ai";

const ChapterSection = ({
  title,
  children,
  addModule,
  deleteChapter,
  updateChapter,
  up,
  down,
}) => {
  const [inputTitle, setInputTitle] = useState("");
  const [inputTitleEdit, setInputTitleEdit] = useState(title);

  const [editTitle, setEditTitle] = useState(false);

  return (
    <div className="border-1 border-reghda-black rounded-xl flex flex-col x-sm:space-y-3 md:space-y-6 2xl:space-y-12 text-right x-sm:text-sm md:text-lg 2xl:text-2xl x-sm:p-2 md:p-4 2xl:p-8">
      <p className="x-sm:text-sm md:text-xl 2xl:text-4xl font-extrabold">
        <span className="float-left flex flex-row items-center justify-center space-x-2">
          <span onClick={deleteChapter} className="cursor-pointer">
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <AiOutlineClose></AiOutlineClose>
            </IconContext.Provider>
          </span>
          <span
            onClick={() => {
              setEditTitle(!editTitle);
            }}
            className="cursor-pointer"
          >
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <AiFillEdit></AiFillEdit>
            </IconContext.Provider>
          </span>
          <button
            onClick={() => {
              up();
            }}
            className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
          >
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <AiOutlineArrowUp></AiOutlineArrowUp>
            </IconContext.Provider>
          </button>
          <button
            onClick={() => {
              down();
            }}
            className="bg-white float-left text-reghda-black py-1 px-1 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
          >
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <AiOutlineArrowDown></AiOutlineArrowDown>
            </IconContext.Provider>
          </button>
        </span>
        {!editTitle && <p className="float-right w-2/3">{title}</p>}
        {editTitle && (
          <div className="float-right flex flex-row items-center justify-center space-x-2">
            <button
              onClick={() => {
                if (inputTitleEdit.length > 0) {
                  updateChapter(inputTitleEdit);
                  setInputTitleEdit("");
                  setEditTitle(false);
                  return;
                }
                alert("يجب عليك إدخل إسم الفصل");
              }}
              className="bg-reghda-blue float-right flex flex-row items-center space-x-2 text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl"
            >
              <span>حفظ</span>
            </button>
            <input
              onChange={(event) => setInputTitleEdit(event.target.value)}
              defaultValue={inputTitleEdit}
              type="text"
              class="w-1/3 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
            />
          </div>
        )}
      </p>
      {children}
      {/* <LessonSection number={1}></LessonSection>
      <LessonSection number={2}></LessonSection>
      <LessonSection number={3}></LessonSection> */}
      <div className="flex flex-row items-center justify-end space-x-2">
        <button
          onClick={(e) => {
            if (inputTitle.length > 0) {
              addModule(inputTitle);
              setInputTitle("");
              return;
            }
            alert("يجب عليك إدخل إسم الدرس");
          }}
          className="bg-reghda-blue float-right flex flex-row items-center space-x-2 text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl"
        >
          <IconContext.Provider
            value={{ size: "1em", style: { color: "#fff" } }}
          >
            <AiOutlinePlus></AiOutlinePlus>
          </IconContext.Provider>
          <span>أضف درس</span>
        </button>
        <input
          onChange={(event) => setInputTitle(event.target.value)}
          value={inputTitle}
          type="text"
          class="w-1/3 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
        />
      </div>
    </div>
  );
};

export default ChapterSection;
