import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

import { confirmShipment } from "../../../../../services/packOrders";
import LoaderModal from "./../LoaderModal";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const ShipmentModal = ({
  isOpen,
  closing,
  mutate,
  id,
  shippingInfo,
  setShippingInfo,
}) => {
  const screenWidth = useWindowWidth();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: screenWidth >= 768 ? "40%" : "80%",
        },
      }}
    >
      <div class="flex flex-row justify-end pb-3">
        <div
          onClick={closing}
          class="self-end cursor-pointer z-50 text-reghda-blue"
        >
          <svg
            class="fill-current text-black"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center x-sm:space-y-5 md:space-y-10 2xl:space-y-16">
        <h3 className="text-reghda-blue font-semibold">معلومات الشحن</h3>
        <input
          onChange={(event) =>
            setShippingInfo({ ...shippingInfo, tracking: event.target.value })
          }
          value={shippingInfo.tracking}
          placeholder="رقم التتبع"
          type="text"
          className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
        />
        <input
          onChange={(event) =>
            setShippingInfo({ ...shippingInfo, company: event.target.value })
          }
          value={shippingInfo.company}
          placeholder="شركة الشحن"
          type="text"
          className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
        />
        <textarea
          onChange={(event) =>
            setShippingInfo({ ...shippingInfo, notice: event.target.value })
          }
          value={shippingInfo.notice}
          rows="3"
          placeholder="ملاحظة"
          type="text"
          className="x-sm:w-full md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
        />
        <button
          onClick={async () => {
            if (
              shippingInfo.notice === "" ||
              shippingInfo.company === "" ||
              shippingInfo.tracking === ""
            ) {
              return;
            }
            setLoading(true);
            let res = await confirmShipment(
              {
                tracking: shippingInfo.tracking,
                company: shippingInfo.company,
                notice: shippingInfo.notice,
                shipping_status: "Shipped",
              },
              id
            );
            if (res && res.status === 200) {
              mutate();
            } else {
              alert("retry! something wrong");
            }
            setShippingInfo({
              tracking: "",
              company: "",
              notice: "",
            });
            setLoading(false);
            closing();
          }}
          className="x-sm:w-full md:w-1/2 bg-reghda-blue text-center text-white py-1 px-2 rounded-xl md:text-base 2xl:text-3xl"
        >
          تاكيد
        </button>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </Modal>
  );
};

export default ShipmentModal;
