import Axios from "axios";
import { domain } from "./../global/domain";

export async function confirmShipment(data, id) {
  try {
    const response = await Axios.patch(
      `https://${domain}/orders/packs/my-orders/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function changeOrderStatus(data) {
  try {
    const response = await Axios.post(`https://${domain}/orders/status`, data);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
