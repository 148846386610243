import React, { useState } from "react";
import PreviewImage from "./PreviewImage";

const AddForm = ({ formState, setFormState, initValues, categories }) => {
  return (
    <form className="flex flex-col pb-4 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right x-sm:text-sm sm:text-base md:text-xl 2xl:text-4xl">
      <fieldset className="flex flex-row items-center justify-end space-x-16">
        <textarea
          onChange={(event) =>
            setFormState({ ...formState, name: event.target.value })
          }
          value={formState.name}
          rows="1"
          id="name"
          type="text"
          class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
        />
        <label className="w-1/6" htmlFor="name">
          إسم المنتج
        </label>
      </fieldset>
      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          {/* {formState.categories.map((category) => (
            <span className="bg-gray-300 text-white p-1 rounded-xl text-xs">
              {category.name} +
            </span>
          ))} */}
          <select
            onChange={(event) => {
              if (event.target.value === "none") {
                return;
              }
              let newCategories = [
                ...formState.categories,
                {
                  id: event.target.value.split("@")[0],
                  name: event.target.value.split("@")[1],
                },
              ];
              // remove duplication
              let unique = Array.from(
                new Set(newCategories.map(JSON.stringify))
              ).map(JSON.parse);

              setFormState({
                ...formState,
                categories: unique,
              });
            }}
            // defaultValue={initValues && initValues.price}
            // defaultChecked={formState.isLocked}
            // value={formState.category}
            id="category"
            class="x-sm:w-32 md:w-48 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          >
            <option value="none" selected>
              اختر صنف
            </option>
            {categories &&
              categories.map((category) => (
                <option value={`${category.id}@${category.name}`}>
                  {category.name}
                </option>
              ))}
            {/* <option value="false">صنف 1</option>
            <option value="true">صنف 2</option>
            <option value="true">صنف 3</option> */}
          </select>
        </div>
        <label className="w-1/6" htmlFor="category">
          صنف
        </label>
      </fieldset>
      <div className="flex x-sm:flex-col x-sm:space-y-2 md:space-y-0 md:flex-row items-center justify-center md:space-x-2">
        {formState.categories.map((category) => (
          <span className="bg-gray-300 text-white p-1 rounded-xl x-sm:text-xxs md:text-xs">
            <span
              onClick={() => {
                setFormState({
                  ...formState,
                  categories: formState.categories.filter(
                    (e) => e.id !== category.id
                  ),
                });
              }}
              className="text-red-500 mr-2"
            >
              X
            </span>{" "}
            {category.name} +
          </span>
        ))}
      </div>
      <fieldset className="flex flex-row items-start justify-end space-x-16">
        <textarea
          onChange={(event) =>
            setFormState({
              ...formState,
              description: event.target.value,
            })
          }
          value={formState.description}
          rows="5"
          id="description"
          type="text"
          class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
        />
        <label className="w-1/6" htmlFor="description">
          وصف المنتج
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <span>دج</span>
          <input
            onChange={(event) =>
              setFormState({ ...formState, price: event.target.value })
            }
            value={formState.price}
            id="price"
            type="text"
            class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          />
        </div>
        <label className="w-1/6" htmlFor="price">
          الثمن
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <input
            onChange={(event) =>
              setFormState({ ...formState, qty: event.target.value })
            }
            value={formState.qty}
            id="qty"
            type="text"
            class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          />
        </div>
        <label className="w-1/6" htmlFor="qty">
          كمية
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <select
            onChange={(event) => {
              setFormState({ ...formState, isAvailable: event.target.value });
            }}
            id="isAvailable"
            class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          >
            <option value="true" selected={formState.isAvailable === "true"}>
              نعم
            </option>
            <option value="false" selected={formState.isAvailable === "false"}>
              لا
            </option>
          </select>
        </div>
        <label className="w-1/6" htmlFor="isAvailable">
          متوفر
        </label>
      </fieldset>

      <fieldset className="flex flex-row items-center justify-end space-x-12">
        <div className="flex flex-row items-center">
          <div>
            <input
              onChange={(event) => {
                if (event.target.files.length) {
                  setFormState({
                    ...formState,
                    images: [...formState.images, event.target.files[0]],
                  });
                }
              }}
              id="upload-img"
              type="file"
              accept="image/*"
              // multiple="multiple"
              style={{ display: "none" }}
            />
            <div
              onClick={() => {
                document.querySelector("#upload-img").click();
              }}
              className="md:ml-44 bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer"
            >
              تحميل
            </div>
          </div>
        </div>
        <label className="w-1/6" htmlFor="upload-img">
          الصور
        </label>
      </fieldset>
      <div className="grid x-sm:grid-cols-2 sm:grid-cols-6 md:grid-cols-6 2xl:grid-cols-6 gap-8 justify-around">
        {formState.images &&
          Array.from(formState.images).map((img) => (
            <PreviewImage
              formState={formState}
              setFormState={setFormState}
              file={img}
            ></PreviewImage>
          ))}
      </div>
    </form>
  );
};

export default AddForm;
