import React, { useState } from "react";
import PackCard from "./content/packs/PackCard";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { deletePack } from "./../../../services/pack";
import { domain } from "./../../../global/domain";
import { useHistory } from "react-router";
const Packs = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [active, setActive] = useState("all");
  const [coursesUrl, setCoursesUrl] = useState(`https://${domain}/packs/all/`);

  const { data, error } = useSWR(coursesUrl, fetcher);
  // const { data2, error2 } = useSWR(`https://ragda.site/courses/paid/`, fetcher);

  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">عروض</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white text-reghda-black x-sm:w-full md:w-11/12 flex flex-row justify-end rounded-lg x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-x-2 md:space-x-8 2xl:space-x-16 font-semibold x-sm:text-sm md:text-lg 2xl:text-4xl">
          <p
            onClick={() => {
              setCoursesUrl(`https://${domain}/packs/all/`);
              mutate(`https://${domain}/packs/all/`);
              setActive("free");
            }}
            // className="cursor-pointer"
            className={`cursor-pointer ${
              active === "free" ? "text-reghda-blue" : ""
            }`}>
            عروض متميزة
          </p>
          <p
            onClick={() => {
              setCoursesUrl(`https://${domain}/packs/all/`);
              mutate(`https://${domain}/packs/all/`);
              setActive("all");
            }}
            // className="cursor-pointer text-reghda-blue"
            className={`cursor-pointer ${
              active === "all" ? "text-reghda-blue" : ""
            }`}>
            عروض عادية
          </p>
        </div>
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row space-x-1 items-center self-end x-sm:text-sm md:text-base 2xl:text-4xl">
            <div className="text-center">
              <button
                onClick={() => history.push("/admin/packs/add-pack")}
                className="bg-reghda-blue text-white  p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none">
                أضاف عرض
              </button>
            </div>
          </div>
          {data &&
            data.map((pack, i) => (
              <PackCard
                deleteCourse={async () => {
                  setLoading(true);
                  let res = await deletePack(pack.id);
                  if (res && res.status === 204) {
                    mutate(coursesUrl);
                  } else {
                    alert("retry! something wrong");
                  }
                  setLoading(false);
                }}
                packDetail={pack}></PackCard>
            ))}
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Packs;
