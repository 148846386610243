import React, { useContext } from "react";
import { Modal, Button } from "@material-ui/core";

export default function SimpleModal({
  open,
  handleClose,
  handleConfirm,
  message = "",
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        direction: "rtl",
      }}>
      <div className=" bg-white border rounded-md border-gray-500 p-4 shadow-custom md:width-600 space-y-2">
        <h1 className="primary-blue">{message}</h1>
        <div className="flex justify-end gap-2">
          <Button
            color="secondary"
            variant="contained"
            onClick={async () => {
              handleConfirm();
              handleClose();
            }}>
            تأكيد
          </Button>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleClose}>
            إلغاء
          </Button>
        </div>
      </div>
    </Modal>
  );
}
