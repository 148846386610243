import React, { useState } from "react";
import { IconContext } from "react-icons";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";

const BlogCard = ({ blogDetail, deleteBlog }) => {
  const history = useHistory();

  return (
    <div className="bg-white flex flex-row items-center justify-center x-sm:p-2 md:p-4 2xl:p-6 rounded-xl shadow-2xl">
      <div className="w-3/4 px-4 text-reghda-blue space-y-4">
        {blogDetail.title && (
          <div className="text-center x-sm:text-base md:text-lg 2xl:text-5xl font-extrabold">
            {blogDetail.title}
          </div>
        )}
        {blogDetail.created_at && (
          <div className="text-center x-sm:text-sm md:text-base 2xl:text-4xl">
            {blogDetail.created_at.substring(0, 10)}
          </div>
        )}
        <div className="text-left float-left flex flex-row items-center justify-center space-x-6">
          <div onClick={() => deleteBlog()} className="cursor-pointer">
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <AiFillDelete></AiFillDelete>
            </IconContext.Provider>
          </div>
          <div
            onClick={() => history.push(`/admin/edit-blog/${blogDetail.id}`)}
            className="cursor-pointer"
          >
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}
            >
              <AiFillEdit></AiFillEdit>
            </IconContext.Provider>
          </div>
        </div>
      </div>
      <div className="x-sm:w-1/2 md:w-1/4">
        {blogDetail.image && (
          <img
            className="pic-fit rounded-xl"
            src={blogDetail.image}
            //   "/images/thumbnail.png"
            alt="default"
          />
        )}
      </div>
    </div>
  );
};

export default BlogCard;
