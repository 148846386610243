import Axios from "axios";
import { domain } from "./../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function addItemToServer(type, data, config) {
  try {
    const response = await Axios.post(
      `https://${domain}/courses/contents/${type}s/`,
      data,
      config
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteItemFromServer(type, id) {
  try {
    const response = await Axios.delete(
      `https://${domain}/courses/contents/${type}/${id}/`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
