import React, { useState } from "react";
import AddForm from "./content/packs/AddForm";
import { useParams, useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";
import { addPack } from "../../../services/pack";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";

const AddPack = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(`https://${domain}/courses/paid/`, fetcher);

  const [formState, setFormState] = useState({
    courses: [],
    courseTitle: "",
    courseDescription: "",
    about: "",
    what_you_get: "",
    is_vip: "true",
    img: null,
    price: null,
    outside_price: null,
    courseInformation: "",
    courseVideo: "",
  });

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">إضافة عرض</h3>
      </div>
      <div className="x-sm:px-2 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row items-center justify-end x-sm:space-x-4 md:space-x-8 2xl:space-x-16 border-b border-gray-200 md:mb-2 md:pb-2 2xl:mb-4 2xl:pb-4 md:text-xl 2xl:text-4xl">
            <div className="text-reghda-blue">معلومات عامة</div>
          </div>
          <div>
            <div className="x-sm:p-4 md:p-8 2xl:p-16">
              {data && (
                <AddForm
                  formState={formState}
                  setFormState={setFormState}
                  courses={data}></AddForm>
              )}
              <div>
                <button
                  onClick={async (e) => {
                    setLoading(true);
                    const formData = new FormData();
                    formData.append(`title`, formState.courseTitle);
                    formData.append(`description`, formState.courseDescription);

                    formData.append(`about_course`, formState.about);

                    formData.append(`price`, formState.price);
                    formData.append(`outside_price`, formState.outside_price);

                    if (formState.img) {
                      formData.append(`picture`, formState.img);
                    }

                    if (formState.what_you_get) {
                      formData.append(`what_you_get`, formState.what_you_get);
                    }

                    if (formState.courseInformation) {
                      formData.append(
                        `course_information`,
                        formState.courseInformation
                      );
                    }

                    if (formState.courseVideo) {
                      formData.append(`course_video`, formState.courseVideo);
                    }

                    if (formState.courses.length) {
                      formState.courses.forEach((course) => {
                        formData.append(`courses`, course.id);
                        return course;
                      });
                    }

                    formData.append(`is_vip`, formState.is_vip);

                    let res = await addPack(formData);

                    setLoading(false);
                    res && res.data && history.push(`/admin/packs`);
                  }}
                  className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl">
                  التالي
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddPack;
