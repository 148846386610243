import React, { useState } from "react";
import { IconContext } from "react-icons";
import { BiArrowBack } from "react-icons/bi";
import Modal from "react-modal";
import MediaCenterOnlyVideo from "./MediaCenterOnlyVideo";
import { useWindowWidth } from "@react-hook/window-size/throttled";
import TextEditorDescription from "./TextEditorDescription";
import TextEditorAbout from "./TextEditorAbout";
import TextEditorInformation from "./TextEditorInformation";
import TextEditorWhatYouGet from "./TextEditorWhatYouGet";
import LoaderModal from "./../LoaderModal";

const AddForm = ({ formState, setFormState, initValues, courses }) => {
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [chooseOpen, setChooseOpen] = useState(true);
  const [youtubeUploadOpen, setYoutubeUploadOpen] = useState(false);
  const [mediaUploadOpen, setMediaUploadOpen] = useState(false);
  const [youtubeUrl, SetYoutubeUrl] = useState("");
  const [buyInfo, setBuyInfo] = useState(true);

  const screenWidth = useWindowWidth();

  const addItem = (data) => {
    setFormState({ ...formState, courseVideo: data.url });
  };

  return (
    <form className="flex flex-col x-sm:space-y-3 md:space-y-6 2xl:space-y-12 text-right x-sm:text-sm md:text-xl 2xl:text-4xl">
      <div className="flex flex-row items-center justify-end space-x-4 border-b border-gray-200 pb-4">
        <span
          onClick={() => setBuyInfo(false)}
          className={`cursor-pointer ${!buyInfo && "text-reghda-blue"}`}
        >
          معلومات بعد الشراء
        </span>
        <span
          onClick={() => setBuyInfo(true)}
          className={`ml-8 cursor-pointer ${buyInfo && "text-reghda-blue"}`}
        >
          معلومات قبل الشراء
        </span>
      </div>
      <fieldset className="flex flex-row items-center justify-end space-x-16">
        <textarea
          onChange={(event) =>
            setFormState({ ...formState, courseTitle: event.target.value })
          }
          // defaultValue={initValues && initValues.courseTitle}
          value={formState.courseTitle}
          rows="1"
          id="title"
          type="text"
          class="w-2/4 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
        />
        <label className="w-1/6" htmlFor="title">
          عنوان عرض
        </label>
      </fieldset>

      {buyInfo && (
        <fieldset className="flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
          <p className="flex flex-row items-center justify-center space-x-2">
            {formState.courseVideo && (
              <span
                onClick={() => {
                  setFormState({ ...formState, courseVideo: "" });
                }}
                className="text-red-500 cursor-pointer"
              >
                حذف
              </span>
            )}
            {formState.courseVideo && (
              <span className="text-green-300">تم التحميل</span>
            )}
          </p>
          <div className="flex flex-row items-center">
            <div>
              <div
                onClick={() => {
                  setIsOpen(true);
                }}
                className="md:ml-44 bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer"
              >
                تحميل
              </div>
            </div>
          </div>
          <label className="w-1/6" htmlFor="courseVideo">
            فيديو توضيحي
          </label>
        </fieldset>
      )}

      <fieldset className="flex flex-row items-center justify-end md:space-x-28 2xl:space-x-40">
        <div className="space-x-4">
          <select
            onChange={(event) => {
              if (event.target.value === "none") {
                return;
              }
              let newCourses = [
                ...formState.courses,
                {
                  id: event.target.value.split("@")[0],
                  title: event.target.value.split("@")[1],
                },
              ];
              // remove duplication
              let unique = Array.from(
                new Set(newCourses.map(JSON.stringify))
              ).map(JSON.parse);

              setFormState({
                ...formState,
                courses: unique,
              });
            }}
            id="courses"
            class="x-sm:w-32 md:w-48 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
          >
            <option value="none" selected>
              الدورات
            </option>
            {courses &&
              courses.map((course) => (
                <option value={`${course.id}@${course.title}`}>
                  {course.title}
                </option>
              ))}
          </select>
        </div>
        <label className="w-1/6" htmlFor="courses">
          إختر الدورات
        </label>
      </fieldset>

      <div className="flex x-sm:flex-col x-sm:space-y-2 md:space-y-0 md:flex-row items-center justify-center md:space-x-2">
        {formState.courses.map((course) => (
          <span className="bg-gray-300 text-white p-1 rounded-xl x-sm:text-xxs md:text-xs">
            <span
              onClick={() => {
                setFormState({
                  ...formState,
                  courses: formState.courses.filter((e) => e.id !== course.id),
                });
              }}
              className="text-red-500 mr-2"
            >
              X
            </span>{" "}
            {course.title} +
          </span>
        ))}
      </div>

      {buyInfo && (
        <fieldset className="flex flex-row items-start justify-end space-x-16 pt-20 ">
          <div className="w-3/4">
            <TextEditorAbout
              initialContentState={formState.about}
              formState={formState}
              setFormState={setFormState}
            ></TextEditorAbout>
          </div>
          <label className="w-1/6 " htmlFor="about">
            حول البرنامج
          </label>
        </fieldset>
      )}

      {buyInfo && (
        <fieldset className="flex flex-row items-start justify-end space-x-16 ">
          <div className="w-3/4">
            <TextEditorDescription
              initialContentState={formState.courseDescription}
              formState={formState}
              setFormState={setFormState}
            ></TextEditorDescription>
          </div>
          <label className="w-1/6 " htmlFor="description">
            وصف عرض
          </label>
        </fieldset>
      )}

      {buyInfo && (
        <fieldset className="flex flex-row items-start justify-end space-x-16 pb-20">
          <div className="w-3/4">
            <TextEditorWhatYouGet
              initialContentState={formState.what_you_get}
              formState={formState}
              setFormState={setFormState}
            ></TextEditorWhatYouGet>
          </div>
          <label className="w-1/6 " htmlFor="description">
            على ماذا ستحصل بعد الشراء
          </label>
        </fieldset>
      )}

      {!buyInfo && (
        <fieldset className="flex flex-row items-start justify-end space-x-16 py-20">
          <div className="w-3/4">
            <TextEditorInformation
              initialContentState={formState.courseInformation}
              formState={formState}
              setFormState={setFormState}
            ></TextEditorInformation>
          </div>
          <label className="w-1/6 " htmlFor="information">
            معلومات للمشترين
          </label>
        </fieldset>
      )}

      {buyInfo && (
        <fieldset className="flex flex-row items-center justify-end x-sm:space-x-2 md:space-x-28 2xl:space-x-40">
          <div className="space-x-4">
            <span>دج</span>
            <input
              onChange={(event) =>
                setFormState({ ...formState, price: event.target.value })
              }
              // defaultValue={initValues && initValues.price}
              value={formState.price}
              id="price"
              type="text"
              class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            />
          </div>
          <label className="w-1/6" htmlFor="price">
            سعر داخل الجزائر
          </label>
        </fieldset>
      )}

      {buyInfo && (
        <fieldset className="flex flex-row items-center justify-end x-sm:space-x-2 md:space-x-28 2xl:space-x-40">
          <div className="space-x-4">
            <span>دولار</span>
            <input
              onChange={(event) =>
                setFormState({
                  ...formState,
                  outside_price: event.target.value,
                })
              }
              // defaultValue={initValues && initValues.price}
              value={formState.outside_price}
              id="price2"
              type="text"
              class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            />
          </div>
          <label className="w-1/6" htmlFor="price2">
            سعر خارج الجزائر
          </label>
        </fieldset>
      )}

      {buyInfo && (
        <fieldset className="flex flex-row items-center justify-end x-sm:space-x-2 md:space-x-28 2xl:space-x-40">
          <div className="space-x-4">
            <select
              onChange={(event) => {
                setFormState({ ...formState, is_vip: event.target.value });
              }}
              value={formState.is_vip}
              id="gift"
              class="w-24 md:ml-44 border-2 border-gray-300 text-right p-1 2xl:p-2 md:text-lg 2xl:text-2xl pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            >
              <option value="false">لا</option>
              <option value="true">نعم</option>
            </select>
          </div>
          <label className="w-1/6" htmlFor="gift">
            عرض متميز
          </label>
        </fieldset>
      )}

      {buyInfo && (
        <fieldset className="flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
          <div className="flex flex-row items-center">
            <p>{formState.img && formState.img.name}</p>
            <div>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setFormState({ ...formState, img: event.target.files[0] });
                  }
                }}
                id="upload-img"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
              <div
                onClick={() => {
                  document.querySelector("#upload-img").click();
                }}
                className="md:ml-44 bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer"
              >
                تحميل
              </div>
            </div>
          </div>
          <label className="w-1/6" htmlFor="upload-img">
            صورة توضيحية
          </label>
        </fieldset>
      )}
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: screenWidth >= 768 ? "40%" : "80%",
          },
        }}
      >
        <div class="mb-16">
          {!chooseOpen && (
            <div
              onClick={() => {
                setChooseOpen(true);
                setYoutubeUploadOpen(false);
                setMediaUploadOpen(false);
              }}
              class="float-left cursor-pointer z-50 text-reghda-blue"
            >
              <IconContext.Provider
                value={{ size: "1.3em", style: { color: "#000" } }}
              >
                <BiArrowBack></BiArrowBack>
              </IconContext.Provider>
            </div>
          )}
          <div
            onClick={() => {
              setIsOpen(false);
              setChooseOpen(true);
              setYoutubeUploadOpen(false);
              setMediaUploadOpen(false);
            }}
            class="float-right cursor-pointer z-50 text-reghda-blue"
          >
            <svg
              class="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </div>
        </div>
        <div className="flex flex-col items-center x-sm:space-y-5 md:space-y-10 2xl:space-y-20 2xl:m-28">
          <p className="text-reghda-blue text-center md:text-3xl 2xl:text-6xl">
            أضافة محتوى
          </p>
          {chooseOpen && (
            <div className="flex x-sm:flex-col md:flex-row justify-between x-sm:text-xs sm:text-sm md:text-base 2xl:text-3xl x-sm:space-y-5 md:space-y-0 md:space-x-5 2xl:space-x-12 md:space-x-10 2xl:space-x-16 items-center md:mr-10 2xl:mr-20">
              <div
                onClick={() => {
                  setChooseOpen(false);
                  setYoutubeUploadOpen(true);
                }}
                className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer"
              >
                <span>ارفع رابط</span>
              </div>
              <div
                onClick={() => {
                  setChooseOpen(false);
                  setMediaUploadOpen(true);
                }}
                className="border-2 border-reghda-blue rounded-lg text-reghda-blue p-4 text-center cursor-pointer"
              >
                <span>ارفع من مكتبتك</span>
              </div>
            </div>
          )}
          {youtubeUploadOpen && (
            <div className="flex x-sm:flex-col-reverse md:flex-row justify-between md:space-x-10 2xl:space-x-16 items-center md:mr-10 2xl:mr-20">
              <button
                onClick={(e) => {
                  if (youtubeUrl.length > 0) {
                    addItem({
                      from: "youtube",
                      url: youtubeUrl,
                    });
                    SetYoutubeUrl("");
                    setIsOpen(false);
                    setChooseOpen(true);
                    setYoutubeUploadOpen(false);
                    setMediaUploadOpen(false);
                  }
                }}
                className="bg-reghda-blue flex flex-row items-center text-white py-1 px-2 rounded-xl x-sm:text-xs md:text-base 2xl:text-3xl"
              >
                <span>أضف الرابط</span>
              </button>
              <input
                onChange={(event) => SetYoutubeUrl(event.target.value)}
                value={youtubeUrl}
                type="text"
                class="x-sm:w-full x-sm:mb-6 md:mb-0 md:w-1/2 text-reghda-black text-right p-1 pr-6 rounded-2xl border-2 border-gray-200 outline-none focus:outline-none  focus:border-pink-300"
              />
            </div>
          )}
          {mediaUploadOpen && (
            <div className="">
              <MediaCenterOnlyVideo
                addItem={addItem}
                setIsOpen={setIsOpen}
              ></MediaCenterOnlyVideo>
            </div>
          )}
        </div>
      </Modal>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </form>
  );
};

export default AddForm;
