import React, { useState, useRef, useEffect } from "react";
import { IconContext } from "react-icons";
import { RiMailFill, RiMailOpenLine, RiReplyFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import parse from "html-react-parser";
import { deleteMail } from "../../../../../services/mail";
import SimpleModal from "./ModalConfirm";

import { Collapse } from "@material-ui/core";
const Sent = ({ detail, mutate }) => {
  const [show, setShow] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <SimpleModal
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirm={async (e) => {
          setLoading(true);
          let res = await deleteMail(detail.id);
          // console.log(res);
          if (res && res.status === 204) {
            mutate();
            alert("تم حذف هذا البريد بنجاح");
          } else {
            alert("لم يتم حذف هذا البريد الإلكتروني");
          }
          setLoading(false);
        }}
        message={"هل أنت متأكد أنك تريد حذف هذا البريد؟"}
      />
      <div className="flex flex-col">
        <div
          onClick={() => setShow(!show)}
          className="flex flex-row items-center justify-center rounded-md cursor-pointer border transform transition-all hover:border-gray-400 ">
          <div className=" w-1/3 flex items-center justify-center space-x-2 h-6 sm:h-auto sm:py-2 border-l border-r ">
            <p className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl sm:h-6 overview-ligne px-2">
              {detail?.receivers?.join(", ")}
            </p>
          </div>
          <div className=" w-1/3 flex items-center justify-start space-x-2 h-6 sm:h-auto sm:py-2 border-l border-r ">
            <p className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl sm:h-6 overview-ligne px-2">
              {detail?.subject}
            </p>
          </div>
          <div className=" w-1/3 flex flex-row items-center justify-between space-x-2 sm:py-2 h-6 sm:h-auto  border-l border-r ">
            <h6 className=" text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl sm:h-6 overview-ligne px-2 w-full text-center">
              {detail?.updated_at?.substring(0, 16)}
            </h6>
            {loading ? (
              <div className="flex items-center justify-center cursor-wait">
                <IconContext.Provider
                  value={{
                    size: "1.5em",
                    style: {
                      color: "#262626",
                    },
                  }}>
                  <MdDelete />
                </IconContext.Provider>
              </div>
            ) : (
              <div
                title="حذف"
                className="flex items-center justify-center hover:opacity-75 "
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenConfirm(true);
                }}>
                <IconContext.Provider
                  value={{
                    size: "1.5em",
                    style: {
                      color: "#262626",
                    },
                  }}>
                  <MdDelete />
                </IconContext.Provider>
              </div>
            )}
          </div>
        </div>
        <div>
          <Collapse in={show}>
            <div className="flex flex-col gap-y-3 p-8 ">
              <div className="w-full flex flex-row mx-auto gap-y-3">
                <p className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl font-bold capitalize px-3">
                  المتلقي
                </p>
                :
                <p className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl px-3">
                  {detail?.receivers?.join(", ")}
                </p>
              </div>
              <div className="w-full flex flex-row mx-auto gap-y-3">
                <h1 className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl font-bold capitalize px-3">
                  موضوع
                </h1>
                :
                <p className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl px-3">
                  {detail?.subject}
                </p>
              </div>
              <div className="w-full flex flex-row mx-auto gap-y-3">
                <h1 className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl font-bold capitalize px-3">
                  المحتوى
                </h1>
                :
                <p className="text-xxs x-sm:text-xs sm:text-base 2xl:text-2xl p-3 border rounded-md w-11/12 mx-auto">
                  {/* {parse(detail?.text_body ? detail?.text_body : null)} */}
                  {detail?.text_body ? parse(detail?.text_body) : null}
                </p>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default Sent;
