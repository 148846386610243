import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { storeContext } from "./../global/store";
import ProtectedRoute from "./ProtectedRoute";
import AdminDashboard from "./../components/admin/Dashboard";
// import Signup from "./../components/admin/Signup";
import Login from "./../components/admin/Login";
import ScrollToTop from "./scrollToTop";

const App = () => {
  const { store } = useContext(storeContext);

  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Switch>
          {/* <Route
            path="/signup"
            exact
            render={(props) => <Signup {...props}></Signup>}
          ></Route> */}
          <Route
            path="/login"
            exact
            render={(props) => <Login overview {...props}></Login>}></Route>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/overview"
            exact
            render={(props) => (
              <AdminDashboard overview {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/normal-courses"
            exact
            render={(props) => (
              <AdminDashboard normalCourses {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/course-subscribers/:id"
            exact
            render={(props) => (
              <AdminDashboard courseSubscribers {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/normal-courses/add-course/:type"
            exact
            render={(props) => (
              <AdminDashboard addCourse {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-course/:type/:id"
            exact
            render={(props) => (
              <AdminDashboard editCourse {...props}></AdminDashboard>
            )}></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/courses-orders"
            exact
            render={(props) => (
              <AdminDashboard coursesOrders {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/courses-orders/:id"
            exact
            render={(props) => (
              <AdminDashboard courseOrderDetail {...props}></AdminDashboard>
            )}></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/packs"
            exact
            render={(props) => (
              <AdminDashboard packs {...props}></AdminDashboard>
            )}></ProtectedRoute>

          {/* <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/packs/add-pack"
            exact
            render={(props) => (
              <AdminDashboard addPack {...props}></AdminDashboard>
            )}
          ></ProtectedRoute> */}

          {/* <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-pack/:id"
            exact
            render={(props) => (
              <AdminDashboard editPack {...props}></AdminDashboard>
            )}
          ></ProtectedRoute> */}

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/packs-orders"
            exact
            render={(props) => (
              <AdminDashboard packsOrders {...props}></AdminDashboard>
            )}></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/packs-orders/:id"
            exact
            render={(props) => (
              <AdminDashboard packOrderDetail {...props}></AdminDashboard>
            )}></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/blogs"
            exact
            render={(props) => (
              <AdminDashboard blogs {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/add-blog"
            exact
            render={(props) => (
              <AdminDashboard addBlog {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/edit-blog/:id"
            exact
            render={(props) => (
              <AdminDashboard editBlog {...props}></AdminDashboard>
            )}></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/media-center"
            exact
            render={(props) => (
              <AdminDashboard mediaCenter {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/media-center/:type"
            exact
            render={(props) => (
              <AdminDashboard mediaCenterType {...props}></AdminDashboard>
            )}></ProtectedRoute>

          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/store"
            exact
            render={(props) => (
              <AdminDashboard store {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/store/add-product"
            exact
            render={(props) => (
              <AdminDashboard addProduct {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/store/edit-product/:id"
            exact
            render={(props) => (
              <AdminDashboard editProduct {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/orders"
            exact
            render={(props) => (
              <AdminDashboard orders {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/orders/:id"
            exact
            render={(props) => (
              <AdminDashboard ordersDetail {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/settings"
            exact
            render={(props) => (
              <AdminDashboard settings {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/who-are-we"
            exact
            render={(props) => (
              <AdminDashboard aboutUs {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/mails"
            exact
            render={(props) => (
              <AdminDashboard mails {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/users"
            exact
            render={(props) => (
              <AdminDashboard Users {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <ProtectedRoute
            isLogged={store.isLogged}
            path="/admin/user/:id"
            exact
            render={(props) => (
              <AdminDashboard User {...props}></AdminDashboard>
            )}></ProtectedRoute>
          <Redirect to="/admin/overview"></Redirect>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
