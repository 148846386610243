import React, { useContext } from "react";
import { IconContext } from "react-icons";
import { AiOutlineDollar, AiOutlinePlaySquare } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { iconsColor } from "./../../../../../global/iconsColor";
import useSwr from "swr";
import { fetcher } from "../../../../../services/fetcher";
import { domain } from "./../../../../../global/domain";
import { getTheUserFromStorage } from "../../../../../services/auth";
import { storeContext } from "../../../../../global/store";

const FirstCardsSection = () => {
  const { store, setStore } = useContext(storeContext);

  const { data, error } = useSwr(`https://${domain}/overview/all`, fetcher);
  if (data) {
    console.log(data);
  }

  return (
    <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col x-sm:space-y-4 md:space-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
      {store.isLogged &&
        getTheUserFromStorage() &&
        getTheUserFromStorage().is_superuser && (
          <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-lg 2xl:text-4xl x-sm:p-4 md:p-6 2xl:p-12 rounded-lg shadow-2xl">
            <IconContext.Provider
              value={{ size: "3em", style: { color: iconsColor } }}>
              <AiOutlineDollar></AiOutlineDollar>
            </IconContext.Provider>
            <div className="text-center">
              <p>المبيعات</p>
              {data && (
                <p className="text-reghda-blue flex flex-row items-center justify-center space-x-2">
                  <span>دج</span>
                  <span>{data.sales}</span>
                </p>
              )}
            </div>
          </div>
        )}

      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-lg 2xl:text-4xl x-sm:p-4 md:p-6 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "3em", style: { color: iconsColor } }}>
          <FaRegUserCircle></FaRegUserCircle>
        </IconContext.Provider>
        <div className="text-center">
          <p>عدد المشتركين</p>
          {data && <p className="text-reghda-blue">{data.subscribers}</p>}
        </div>
      </div>
      <div className="flex-1 bg-white flex flex-row items-center justify-around font-bold text-reghda-black md:text-lg 2xl:text-4xl x-sm:p-4 md:p-6 2xl:p-12 rounded-lg shadow-2xl">
        <IconContext.Provider
          value={{ size: "3em", style: { color: iconsColor } }}>
          <AiOutlinePlaySquare></AiOutlinePlaySquare>
        </IconContext.Provider>
        <div className="text-center">
          <p>عدد الدورات</p>
          {data && <p className="text-reghda-blue">{data.courses}</p>}
        </div>
      </div>
    </div>
  );
};

export default FirstCardsSection;
