import React, { useState } from "react";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";
import { AiTwotoneEdit, AiFillDelete } from "react-icons/ai";
import cn from "classnames";

import { editSaying } from "../../../../../services/aboutUs";
import { mutate } from "swr";
import { domain } from "../../../../../global/domain";

const Say = ({ deleteSay, say, formState, setFormState, setLoading }) => {
  const [edit, setEdit] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  let menuStyle = cn(
    "relative cursor-pointer flex flex-col items-center justify-center md:ml-2 2xl:ml-4  ",
    { dropdown: menuOpen }
  );

  let menuItemsStyle = cn(
    "bg-reghda-black  rounded-lg p-2 absolute hidden top-8 absolute",
    {
      dropdownContent: menuOpen,
    }
  );

  return (
    <div
      key={say.id}
      className="flex x-sm:flex-col-reverse md:flex-row items-center justify-between border-t-2 border-gray-100 md:pt-4 mt-8">
      {!edit && (
        <div className="w-9/12 text-right leading-loose">{say.body}</div>
      )}

      {edit && (
        <button
          onClick={async () => {
            if (formState.author.length < 1 || formState.body.length < 1) {
              return;
            }
            setLoading(true);
            const formData = new FormData();
            formData.append(`author`, formState.author);
            formData.append(`body`, formState.body);
            if (formState.image) {
              formData.append(`image`, formState.image);
            }
            let res = await editSaying(say.id, formData);
            mutate(`https://${domain}/about/sayings/`);
            setFormState({
              author: "",
              body: "",
              image: null,
            });
            setEdit(false);
            setMenuOpen(false);
            setLoading(false);
          }}
          className="bg-reghda-blue p-2 text-white rounded-xl x-sm:text-sm  outline-none focus:outline-none">
          حفظ
        </button>
      )}

      {edit && (
        <div className="w-5/12">
          <fieldset className="w-full flex flex-row items-center justify-center">
            <textarea
              onChange={(event) =>
                setFormState({
                  ...formState,
                  body: event.target.value,
                })
              }
              defaultValue={formState.body}
              placeholder="مقولة"
              rows="1"
              type="text"
              className="w-11/12 border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            />
          </fieldset>
        </div>
      )}

      {!edit && (
        <div className="w-2/12 text-center leading-loose font-extrabold text-reghda-blue">
          <img
            src={say.image}
            alt="review_image"
            className="w-16 h-auto block mx-auto"
          />
          <p>{say.author}</p>
        </div>
      )}

      {edit && (
        <div className="w-4/12">
          <fieldset className="w-full flex flex-row items-center justify-center">
            <textarea
              onChange={(event) =>
                setFormState({
                  ...formState,
                  author: event.target.value,
                })
              }
              defaultValue={formState.author}
              rows="1"
              placeholder="قائل"
              type="text"
              className="w-full border-2 border-gray-300 text-right p-1 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
            />
          </fieldset>
        </div>
      )}
      {edit && (
        <fieldset className="w-2/12 flex flex-row items-center justify-end x-sm:space-x-6 md:space-x-12">
          <div className="flex justify-center items-center">
            <p className="mx-2">{formState.image && formState.image.name}</p>
            <div>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setFormState({
                      ...formState,
                      image: event.target.files[0],
                    });
                  }
                }}
                id="upload-img"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
              <div
                onClick={() => {
                  document.querySelector("#upload-img").click();
                }}
                className=" bg-reghda-black text-reghda-blue py-2 px-4 rounded-xl md:text-base 2xl:text-3xl text-right cursor-pointer">
                تحميل
              </div>
            </div>
          </div>
        </fieldset>
      )}

      <div className="w-1/12 flex x-sm:flex-col-reverse x-sm:space-y-2 md:flex-row items-center pt-2 md:pt-0">
        <div className={`${menuStyle} absolute md:pr-2 md:pl-4`}>
          <div onClick={() => setMenuOpen(!menuOpen)} className="py-2">
            <IconContext.Provider
              value={{ size: "1.5em", style: { color: "#5b5b5b" } }}>
              <BsThreeDots></BsThreeDots>
            </IconContext.Provider>
          </div>
          <div className={menuItemsStyle}>
            <div
              onClick={() => {
                setEdit(!edit);
                setFormState({
                  author: say.author,
                  body: say.body,
                });
              }}
              className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer">
              <span className="text-sm">تعديل</span>
              <IconContext.Provider
                value={{ size: "1em", style: { color: "#fff" } }}>
                <AiTwotoneEdit></AiTwotoneEdit>
              </IconContext.Provider>
            </div>
            <div
              onClick={deleteSay}
              className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer">
              <span className="text-sm">حذف</span>
              <IconContext.Provider
                value={{ size: "1em", style: { color: "#fff" } }}>
                <AiFillDelete></AiFillDelete>
              </IconContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Say;
