import Axios from "axios";
import { domain } from "./../global/domain";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export async function addPack(data) {
  try {
    const response = await Axios.post(`https://${domain}/packs/all/`, data);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function updatePack(id, data) {
  try {
    const response = await Axios.patch(
      `https://${domain}/packs/all/${id}/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deletePack(id) {
  try {
    const response = await Axios.delete(`https://${domain}/packs/all/${id}/`);
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
