import React from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ButtonBase } from "@material-ui/core/";

const Pagination = ({ data, pagesize, setPage, page }) => {
  return (
    <div
      style={{ direction: "rtl" }}
      className="flex flex-row items-center justify-end w-full gap-x-6 ">
      <ButtonBase
        onClick={() => {
          if (data?.previous) setPage(page - 1);
        }}
        disabled={!data?.previous}>
        <ArrowForwardIosIcon
          style={{ color: `${data?.previous ? "#926fdc" : "#e0e0eb"}` }}
          fontSize="small"
        />
      </ButtonBase>
      <span className=" font-Lato text-xxs x-sm:text-xs sm:text-base capitalize ">
        الصفحة: {page + 1}
        {data?.count && Math.ceil(data?.count / pagesize) > 1
          ? ` ${"من"} ${Math.ceil(data.count / pagesize)} `
          : ` ${"من"} ${1} `}
      </span>
      <span className=" font-Lato text-xxs x-sm:text-xs sm:text-base capitalize ">
        ({data?.count ? data?.count : 0} نتيجة موجودة )
      </span>
      <ButtonBase
        onClick={() => {
          if (data?.next) setPage(page + 1);
        }}
        disabled={!data?.next}>
        <ArrowBackIosIcon
          style={{ color: `${data?.next ? "#926fdc" : "#e0e0eb"}` }}
          fontSize="small"
        />
      </ButtonBase>
    </div>
  );
};

export default Pagination;
