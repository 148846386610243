import React, { useContext, useState, useEffect } from "react";
import { storeContext } from "./../../global/store";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  deleteTheUserAndTokenFromStorage,
  getTheUserFromStorage,
} from "./../../services/auth";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import { domain } from "./../../global/domain";

const Sidebar = () => {
  const [image, setImage] = useState(null);
  const history = useHistory();

  const { data, error } = useSWR(
    `https://${domain}/users/profile/${
      getTheUserFromStorage() ? getTheUserFromStorage().user_id : 3
    }`,
    fetcher
  );
  useEffect(() => {
    data && setImage(data.User.image);
  }, [data]);

  const { store, setStore } = useContext(storeContext);

  const notification = useSWR(
    `https://${domain}/overview/notifications`,
    fetcher
  );

  const changeVisibility = () => {
    const navToggle = document.getElementsByClassName("toggle");
    for (let i = 0; i < navToggle.length; i++) {
      navToggle.item(i).classList.toggle("hidden");
    }

    const navBarToggle = document.getElementsByClassName("nav__item");
    for (let i = 0; i < navBarToggle.length; i++) {
      console.log("nav item", navBarToggle.item(i).classList);
      navBarToggle.item(i).classList.toggle("hidden__nav");
    }
  };

  return (
    <>
      <div className=" md:hidden text-center text-white flex flex-col-reverse items-center w-full">
        <div className="bg-reghda-blue hidden__nav nav__item  p-2 flex flex-col space-y-2  items-center x-sm:rounded-2xl mt-2 absolute width-60 ">
          <div className="text-white x-sm:text-sm md:text-lg 2xl:text-2xl hover:text-pink-100 x-sm:p-1 x-sm:my-2 md:p-4 md:my-4 2xl:p-8 2xl:my-8 border-t-2 border-b-2 border-white flex flex-row items-center justify-around x-sm:space-x-1 md:space-x-2 2xl:space-x-4">
            <img
              onClick={() => history.push("/admin/settings")}
              className="flex-1 rounded-full x-sm:w-10 x-sm:h-10 md:w-14 md:h-14 2xl:w-28 2xl:h-28 picfit cursor-pointer"
              alt="logo"
              src={
                image
                  ? typeof image === "string"
                    ? image
                    : URL.createObjectURL(image)
                  : "/images/admin/default-user.png"
              }
            />
            {/* <h4>User 1</h4> */}
            <h4
              onClick={() => {
                setStore({
                  ...store,
                  isLogged: false,
                });
                deleteTheUserAndTokenFromStorage();
              }}
              className="font-extrabold cursor-pointer">
              تسجيل الخروج
            </h4>
          </div>
          <ul className="text-white text-right x-sm:text-sm md:text-xl 2xl:text-4xl flex flex-col items-center justify-around x-sm:space-y-2 md:space-y-4 2xl:space-y-12">
            <li className="hover:text-pink-100">
              <NavLink
                onClick={(e) => changeVisibility(e)}
                to="/admin/overview">
                نظرة عامة
              </NavLink>
            </li>

            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_staff && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    to="/admin/normal-courses">
                    دورات العادية
                  </NavLink>
                </li>
              )}

            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_superuser && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    className="flex flex-row items-center justify-center space-x-2"
                    to="/admin/courses-orders">
                    <span>طلبيات الدورات</span>
                    {notification.data &&
                      notification.data.courses_and_books_orders > 0 && (
                        <span className="text-white bg-yellow-300 rounded-full p-4 2xl:p-6 h-6 w-6 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center">
                          {notification.data.courses_and_books_orders}
                        </span>
                      )}
                  </NavLink>
                </li>
              )}

            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_superuser && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    className="flex flex-row items-center justify-center space-x-2"
                    to="/admin/packs-orders">
                    <span>طلبيات العروض</span>
                    {notification.data &&
                      notification.data.packs_orders > 0 && (
                        <span className="text-white bg-yellow-300 rounded-full p-4 2xl:p-6 h-6 w-6 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center">
                          {notification.data.packs_orders}
                        </span>
                      )}
                  </NavLink>
                </li>
              )}

            <li className="hover:text-pink-100">
              <NavLink onClick={(e) => changeVisibility(e)} to="/admin/blogs">
                مقالات
              </NavLink>
            </li>
            <li className="hover:text-pink-100">
              <NavLink
                onClick={(e) => changeVisibility(e)}
                to="/admin/media-center">
                مكتبة الوسائط
              </NavLink>
            </li>

            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_staff && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    to="/admin/store">
                    المتجر
                  </NavLink>
                </li>
              )}

            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_superuser && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    className="flex flex-row items-center justify-center space-x-2"
                    to="/admin/orders">
                    <span>طلبيات المتجر</span>
                    {notification.data &&
                      notification.data.store_orders > 0 && (
                        <span className="text-white bg-yellow-300 rounded-full p-4 2xl:p-6 h-6 w-6 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center">
                          {notification.data.store_orders}
                        </span>
                      )}
                  </NavLink>
                </li>
              )}

            <li className="hover:text-pink-100">
              <NavLink
                onClick={(e) => changeVisibility(e)}
                to="/admin/settings">
                الاعدادات
              </NavLink>
            </li>

            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_staff && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    to="/admin/who-are-we">
                    ماذا قالوا
                  </NavLink>
                </li>
              )}
            {store.isLogged &&
              getTheUserFromStorage() &&
              getTheUserFromStorage().is_staff && (
                <li className="hover:text-pink-100">
                  <NavLink
                    onClick={(e) => changeVisibility(e)}
                    to="/admin/mails">
                    رسائل البريد
                  </NavLink>
                </li>
              )}
          </ul>
        </div>
        <div className="flex flex-row bg-reghda-blue  x-sm:rounded-2xl width-60 justify-center py-2 mt-2">
          <div className="flex flex-col items-center">
            <img
              className="flex-1 x-sm:w-16 x-sm:h-16 md:w-32 md:h-32 2xl:w-48 2xl:h-48 "
              alt="logo"
              src="/images/Logo.png"
            />
          </div>
          <button
            id="hamburger"
            onClick={(e) => changeVisibility(e)}
            style={{ border: "none", outline: "none" }}>
            <img
              className="toggle block"
              src="https://img.icons8.com/f1f1f1/fluent-systems-regular/2x/menu-squared-2.png"
              alt="menu"
              width="40"
              height="40"
            />
            <img
              className="toggle hidden"
              src="https://img.icons8.com/f1f1f1/fluent-systems-regular/2x/close-window.png"
              alt="quite"
              width="40"
              height="40"
            />
          </button>
        </div>
      </div>
      <div className="x-sm:hidden x-sm:p-8 md:flex bg-reghda-blue rounded-xl md:my-4 md:mr-4 w-1/5 flex-none flex flex-col items-center justify-start">
        <div className="p-4 flex flex-col items-center">
          <img
            className="flex-1 md:w-32 md:h-32 2xl:w-48 2xl:h-48"
            alt="logo"
            src="/images/Logo.png"
          />
        </div>
        <div className="text-white md:text-lg 2xl:text-2xl hover:text-pink-100 md:p-4 md:my-4 2xl:p-8 2xl:my-8 border-t-2 border-b-2 border-white flex flex-row items-center justify-around md:space-x-2 2xl:space-x-4">
          <img
            onClick={() => history.push("/admin/settings")}
            className="flex-1 rounded-full md:w-14 md:h-14 2xl:w-28 2xl:h-28 picfit cursor-pointer"
            alt="logo"
            src={
              image
                ? typeof image === "string"
                  ? image
                  : URL.createObjectURL(image)
                : "/images/admin/default-user.png"
            }
          />
          {/* <h4>User 1</h4> */}
          <h4
            onClick={() => {
              setStore({
                ...store,
                isLogged: false,
              });
              deleteTheUserAndTokenFromStorage();
            }}
            className="font-extrabold cursor-pointer">
            تسجيل الخروج
          </h4>
        </div>
        <ul className="text-white text-right md:text-xl 2xl:text-4xl flex flex-col items-center justify-around md:space-y-4 2xl:space-y-12">
          <li className="hover:text-pink-100">
            <NavLink to="/admin/overview">نظرة عامة</NavLink>
          </li>

          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_staff && (
              <li className="hover:text-pink-100">
                <NavLink to="/admin/normal-courses">دورات العادية</NavLink>
              </li>
            )}
          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_staff && (
              <li className="hover:text-pink-100">
                <NavLink to="/admin/users">المشتركين</NavLink>
              </li>
            )}

          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_superuser && (
              <li className="hover:text-pink-100">
                <NavLink
                  className="flex flex-row items-center justify-center space-x-2"
                  to="/admin/courses-orders">
                  <span>طلبيات الدورات</span>
                  {notification.data &&
                    notification.data.courses_and_books_orders > 0 && (
                      <span className="text-white bg-yellow-300 rounded-full p-4 2xl:p-6 h-6 w-6 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center">
                        {notification.data.courses_and_books_orders}
                      </span>
                    )}
                </NavLink>
              </li>
            )}

          {/* <li className="hover:text-pink-100">
            <NavLink onClick={(e) => changeVisibility(e)} to="/admin/packs">
              عروض
            </NavLink>
          </li> */}

          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_superuser && (
              <li className="hover:text-pink-100">
                <NavLink
                  onClick={(e) => changeVisibility(e)}
                  className="flex flex-row items-center justify-center space-x-2"
                  to="/admin/packs-orders">
                  <span>طلبيات العروض</span>
                  {notification.data && notification.data.packs_orders > 0 && (
                    <span className="text-white bg-yellow-300 rounded-full p-4 2xl:p-6 h-6 w-6 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center">
                      {notification.data.packs_orders}
                    </span>
                  )}
                </NavLink>
              </li>
            )}

          <li className="hover:text-pink-100">
            <NavLink to="/admin/blogs">مقالات</NavLink>
          </li>
          <li className="hover:text-pink-100">
            <NavLink to="/admin/media-center">مكتبة الوسائط</NavLink>
          </li>

          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_staff && (
              <li className="hover:text-pink-100">
                <NavLink to="/admin/store">المتجر</NavLink>
              </li>
            )}

          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_superuser && (
              <li className="hover:text-pink-100">
                <NavLink
                  className="flex flex-row items-center justify-center space-x-2"
                  to="/admin/orders">
                  <span>طلبيات المتجر</span>
                  {notification.data && notification.data.store_orders > 0 && (
                    <span className="text-white bg-yellow-300 rounded-full p-4 2xl:p-6 h-6 w-6 2xl:h-9 2xl:w-9 text-center flex flex-row items-center justify-center">
                      {notification.data.store_orders}
                    </span>
                  )}
                </NavLink>
              </li>
            )}

          <li className="hover:text-pink-100">
            <NavLink to="/admin/settings">الاعدادات</NavLink>
          </li>

          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_staff && (
              <li className="hover:text-pink-100">
                <NavLink to="/admin/who-are-we">ماذا قالوا</NavLink>
              </li>
            )}
          {store.isLogged &&
            getTheUserFromStorage() &&
            getTheUserFromStorage().is_staff && (
              <li className="hover:text-pink-100">
                <NavLink to="/admin/mails">رسائل البريد</NavLink>
              </li>
            )}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
