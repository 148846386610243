import React from "react";

import { IconContext } from "react-icons";
import { AiFillFolder } from "react-icons/ai";
import { Link } from "react-router-dom";
import { iconsColor } from "./../../../global/iconsColor";

const MediaCenter = () => {
  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          مكتبة الوسائط
        </h3>
      </div>
      <div className="x-sm:px-3 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex x-sm:flex-col md:flex-row x-sm:p-2 md:p-4 2xl:p-12 md:text-sm 2xl:text-4xl">
          <Link
            to="/admin/media-center/files"
            className="flex flex-col items-center"
          >
            <IconContext.Provider
              value={{ size: "8em", style: { color: iconsColor } }}
            >
              <AiFillFolder></AiFillFolder>
            </IconContext.Provider>
            <p>مرفقات</p>
          </Link>
          <Link
            to="/admin/media-center/images"
            className="flex flex-col items-center"
          >
            <IconContext.Provider
              value={{ size: "8em", style: { color: iconsColor } }}
            >
              <AiFillFolder></AiFillFolder>
            </IconContext.Provider>
            <p>صور</p>
          </Link>
          <Link
            to="/admin/media-center/audios"
            className="flex flex-col items-center"
          >
            <IconContext.Provider
              value={{ size: "8em", style: { color: iconsColor } }}
            >
              <AiFillFolder></AiFillFolder>
            </IconContext.Provider>
            <p>اوديو</p>
          </Link>
          <Link
            to="/admin/media-center/videos"
            className="flex flex-col items-center"
          >
            <IconContext.Provider
              value={{ size: "8em", style: { color: iconsColor } }}
            >
              <AiFillFolder></AiFillFolder>
            </IconContext.Provider>
            <p>فيديو</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MediaCenter;
