import React, { useState } from "react";
import Modal from "react-modal";
import { useWindowWidth } from "@react-hook/window-size/throttled";

const PictureModal = ({ isOpen, closing, url }) => {
  const screenWidth = useWindowWidth();

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          borderColor: "rgba(0, 0, 0, 0)",
          padding: 0,
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          maxHeight: "90%",
        },
      }}
    >
      <div class="flex flex-row justify-start p-2">
        <div onClick={closing} class="self-end cursor-pointer text-reghda-blue">
          <svg
            class="fill-current text-red-600"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center">
        <a
          className="cursor-pointer"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <img className="w-80" src={url} alt="proof" />
        </a>
      </div>
      <div class="flex flex-row justify-start p-2">
        <div class="self-end text-transparent">
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
    </Modal>
  );
};

export default PictureModal;
