import React, { useState, useRef } from "react";

const TopSentInbox = () => {
  return (
    <div className="x-sm:w-full md:w-11/12 flex x-sm:flex-col x-sm:space-y-4 md:space-y-0 md:flex-row md:space-x-8 2xl:space-x-16">
      <div className="flex-1 bg-white flex flex-row-reverse items-center justify-between font-bold text-reghda-black md:text-lg 2xl:text-4xl x-sm:p-4 md:p-6 2xl:p-12 rounded-lg shadow-2xl">
        <div className="text-center">
          <p>البريد المرسَل</p>
        </div>
      </div>
    </div>
  );
};

export default TopSentInbox;
