import React from "react";
import DashboardContainer from "./DashboardContainer";
import ContentContainer from "./pages/ContentContainer";
import Sidebar from "./SideBar";
import Overview from "./pages/Overview";
import NormalCourses from "./pages/NormalCourses";
import AddCourse from "./pages/AddCourse";
import CourseSubscribers from "./pages/CourseSubscribers";
import EditCourse from "./pages/EditCourse";
import MediaCenter from "./pages/MediaCenter";
import MediaCenterType from "./pages/MediaCenterType";
import Settings from "./pages/Settings";
import Store from "./pages/Store";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/OrderDetail";
import CoursesOrders from "./pages/CoursesOrders";
import CourseOrderDetail from "./pages/CourseOrderDetail";
import AboutUs from "./pages/content/AboutUs/EditAboutUs";
import Blogs from "./pages/Blogs";
import AddBlog from "./pages/AddBlog";
import EditBlog from "./pages/EditBlog";
import Packs from "./pages/Packs";
import AddPack from "./pages/AddPack";
import EditPack from "./pages/EditPack";
import PacksOrders from "./pages/PacksOrders";
import PackOrderDetail from "./pages/PackOrderDetail";
import UsersPage from "./pages/UsersPage";
import UserDetails from "./pages/UserDetails";
import Mails from "./pages/Mails";

const Dashboard = ({
  overview,
  normalCourses,
  addCourse,
  editCourse,
  coursesOrders,
  courseOrderDetail,
  packs,
  addPack,
  editPack,
  packsOrders,
  packOrderDetail,
  blogs,
  addBlog,
  editBlog,
  mediaCenter,
  mediaCenterType,
  store,
  addProduct,
  editProduct,
  orders,
  ordersDetail,
  settings,
  courseSubscribers,
  aboutUs,
  mails,
  Users,
  User,
}) => {
  const content = () => {
    switch (true) {
      case overview:
        return <Overview></Overview>;
      case normalCourses:
        return <NormalCourses></NormalCourses>;
      case addCourse:
        return <AddCourse></AddCourse>;
      case editCourse:
        return <EditCourse></EditCourse>;
      case courseSubscribers:
        return <CourseSubscribers></CourseSubscribers>;
      case coursesOrders:
        return <CoursesOrders></CoursesOrders>;
      case courseOrderDetail:
        return <CourseOrderDetail></CourseOrderDetail>;
      case packs:
        return <Packs></Packs>;
      case addPack:
        return <AddPack></AddPack>;
      case editPack:
        return <EditPack></EditPack>;
      case packsOrders:
        return <PacksOrders></PacksOrders>;
      case packOrderDetail:
        return <PackOrderDetail></PackOrderDetail>;
      case blogs:
        return <Blogs></Blogs>;
      case addBlog:
        return <AddBlog></AddBlog>;
      case editBlog:
        return <EditBlog></EditBlog>;
      case settings:
        return <Settings></Settings>;
      case store:
        return <Store></Store>;
      case addProduct:
        return <AddProduct></AddProduct>;
      case editProduct:
        return <EditProduct></EditProduct>;
      case orders:
        return <Orders></Orders>;
      case ordersDetail:
        return <OrderDetail></OrderDetail>;
      case mediaCenter:
        return <MediaCenter></MediaCenter>;
      case mediaCenterType:
        return <MediaCenterType></MediaCenterType>;
      case aboutUs:
        return <AboutUs></AboutUs>;
      case mails:
        return <Mails></Mails>;
      case Users:
        return <UsersPage></UsersPage>;
      case User:
        return <UserDetails></UserDetails>;
      default:
        return <Overview></Overview>;
    }
  };

  return (
    <DashboardContainer>
      <ContentContainer>{content()}</ContentContainer>
      <Sidebar></Sidebar>
    </DashboardContainer>
  );
};

export default Dashboard;
