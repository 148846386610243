import React, { useState } from "react";
import AddForm from "./content/store/AddForm";
import { useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";

import { domain } from "./../../../global/domain";
import { addProduct } from "../../../services/products";

const AddProduct = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/store/categories/`,
    fetcher
  );

  const [formState, setFormState] = useState({
    name: "",
    categories: [],
    images: [],
    description: "",
    isAvailable: "true",
    price: null,
    qty: null,
  });

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold"> اضافة منتج</h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg flex flex-col x-sm:p-2 md:p-4 2xl:p-12">
          <div className="flex flex-row items-center justify-end md:space-x-8 2xl:space-x-16 border-b border-gray-200 md:mb-2 md:pb-2 2xl:mb-4 2xl:pb-4 md:text-xl 2xl:text-4xl">
            <div>محتوى</div>
          </div>
          <div>
            <div className="x-sm:p-2 md:p-8 2xl:p-16">
              {data && (
                <AddForm
                  formState={formState}
                  setFormState={setFormState}
                  categories={data.results}></AddForm>
              )}
              <div>
                <button
                  onClick={async (e) => {
                    if (formState.price < 134) {
                      alert("الحد الأدنى للسعر هو 134 دج");
                      return;
                    }
                    setLoading(true);
                    const formData = new FormData();
                    formData.append(`name`, formState.name);
                    formData.append(`description`, formState.description);
                    formData.append(`price`, formState.price);
                    formData.append(`is_available`, formState.isAvailable);
                    formData.append(`qty_available`, formState.qty);

                    if (formState.categories.length) {
                      formState.categories.forEach((category) => {
                        formData.append(`categories_list`, category.id);
                        return category;
                      });
                    }

                    if (formState.images.length) {
                      formState.images.forEach((image) => {
                        formData.append(`images_list`, image);
                        return image;
                      });
                    }

                    let res = await addProduct(formData);
                    console.log(res, "👀");
                    setLoading(false);
                    res && res.data && history.push(`/admin/store`);
                  }}
                  className="bg-reghda-blue float-left text-white py-2 px-4 rounded-xl md:text-base 2xl:text-3xl">
                  التالي
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default AddProduct;
