import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LoaderModal from "./content/LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { domain } from "./../../../global/domain";
import DownloadModal from "./content/courses-orders/DownloadModal";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const CoursesOrders = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  const { data, error } = useSWR(
    `https://${domain}/orders/my-orders/`,
    fetcher
  );

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const exportToCSV = (apiData) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, `courses-orders.xlsx`);
  };

  return (
    <div className="min-h-screen h-full flex flex-col overflow-y-auto">
      <div className="p-8 text-right">
        <h3 className="md:text-2xl 2xl:text-6xl font-extrabold">
          طلبيات الدورات
        </h3>
      </div>
      <div className="x-sm:px-4 md:px-6 2xl:p-12 mb-16 space-y-10">
        <div className="bg-white x-sm:w-full md:w-11/12 rounded-lg md:p-8 2xl:p-16 flex flex-col md:space-y-8 2xl:space-y-16">
          {data && (
            <div className="flex flex-row space-x-4 items-center justify-end x-sm:text-sm md:text-lg 2xl:text-3xl">
              <button
                onClick={() => {
                  // setIsOpen(true)
                  exportToCSV(data);
                }}
                className="bg-reghda-blue text-white py-2 px-4 rounded-xl x-sm:text-xxs sm:text-xs md:text-base 2xl:text-3xl outline-none focus:outline-none">
                تحميل
              </button>
            </div>
          )}
          <table className="border-collapse w-full text-center x-sm:text-xs sm:text-sm md:text-lg 2xl:text-3xl">
            <tr>
              <th className="border-2 border-solid border-gray-200 p-4">
                الحالة
              </th>
              <th className="border-2 border-solid border-gray-200 p-4">
                زبون
              </th>
              <th className="border-2 border-solid border-gray-200 p-4">رقم</th>
            </tr>
            {data &&
              data.map((order, i) => {
                return (
                  <tr
                    onClick={() => {
                      history.push(`/admin/courses-orders/${order.id}`);
                    }}
                    className="w-full hover:text-gray-300 cursor-pointer"
                    key={i}>
                    <td className="border-2 border-solid border-gray-200 p-4">
                      {order.status === "مقبول" && (
                        <span className="text-green-300">مقبول</span>
                      )}
                      {order.status === "في الإنتظار" && (
                        <span className="text-yellow-300">في الإنتظار</span>
                      )}
                      {order.status === "مرفوض" && (
                        <span className="text-red-500">مرفوض</span>
                      )}
                    </td>

                    <td className="border-2 border-solid border-gray-200 p-4">
                      {`${order.user.family_name} ${order.user.given_name}`}
                    </td>
                    <td className="border-2 border-solid border-gray-200 p-4">
                      {order.id}
                    </td>
                  </tr>
                );
              })}
          </table>
          {/* <div className="flex flex-row space-x-4 items-center justify-end md:text-base 2xl:text-2xl">
            <p className="bg-reghda-blue px-2 rounded-l-2xl text-white cursor-pointer">
              السابق
            </p>
            <p className="text-reghda-blue">1</p>
            <p className="bg-reghda-blue px-2 rounded-r-2xl text-white cursor-pointer">
              التالي
            </p>
          </div> */}
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
      {/* <DownloadModal
        isOpen={modalIsOpen}
        closing={() => setIsOpen(false)}
        data={data}
      ></DownloadModal> */}
    </div>
  );
};

export default CoursesOrders;
